
function logout(){
    localStorage.removeItem('token')
    return
}

export function handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if ((response.status === 401) && window.location.pathname !== "/login") {
          logout();
          // window.location.reload();
        }
        const error = (data && data.error) || response.statusText;
        return Promise.reject(error);
      }
      return data;
    });
}