
import { handleResponse } from "../../utils/utils"
export const ordersService= {
    uploadOrder,
    getAllOrders
}

const config = {
    apiUrl: process.env.REACT_APP_API_URL,
};

function uploadOrder(data) {
    const token = localStorage.getItem('token')
const requestOptions = {
    method: "POST",
    body: data,
    headers: {'Authorization': 'Bearer ' + token }
};
return fetch(`${config.apiUrl}/api/orders`, requestOptions)
    .then(handleResponse)
    .then((data) => {
        return data;
    });
}

function getAllOrders() {
    const token = localStorage.getItem('token')
const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", 'Authorization': 'Bearer ' + token }
};
return fetch(`${config.apiUrl}/api/orders/me`, requestOptions)
    .then(handleResponse)
    .then((data) => {
        return data;
    });
}

  
  