const SocialButton = ({ icon }) => {
  return (
    <button
      type='button'
      className='inline-flex items-center justify-center w-28 h-16 mr-2 bg-white transition-colors duration-150 rounded-lg'>
      <img src={icon} alt='Social Login' className='bg-white' />
    </button>
  );
};

export default SocialButton;
