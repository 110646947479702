export const userConstants = {
    LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
    LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
    LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

    REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
    REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
    REGISTER_FAILURE: "USERS_REGISTER_FAILURE",

    GET_USER_REQUEST: "GET_CURRENT_USER_REQUEST",
    GET_USER_SUCCESS: "GET_CURRENT_USER_SUCCESS",
    GET_USER_FAILURE: "GET_CURRENT_USER_FAILURE"
}

export const orderConstants = {
    UPLOAD_REQUEST: "ORDER_UPLOAD_REQUEST",
    UPLOAD_SUCCESS: "ORDER_UPLOAD_SUCCESS",
    UPLOAD_FAILURE: "ORDER_UPLOAD_FAILURE",

    GET_REQUEST: "ORDER_GET_REQUEST",
    GET_SUCCESS: "ORDER_GET_SUCCESS",
    GET_FAILURE: "ORDER_GET_FAILURE",
}