import { useEffect, useState } from "react";

import OrderAttachments from "./OrderAttachments";
import OrderBill from "./OrderBill";
import OrderDescription from "./OrderDescription";
import { connect } from "react-redux"
import { orderActions } from "../../redux/actions/orders"
import OrderDetails from "./OrderDetails";



const CreateOrder = ({uploadOrder, user}) => {
  const [tabOpen, setTabOpen] = useState(1);
  const [jobTitle, setJobTitle] = useState('');
  const [education, setEducation] = useState('');
  const [pages, setPages] = useState(0);
  const [spaces, setSpaces] = useState('');
  const [citation, setCitation] = useState('');
  const [topic, setTopic] = useState('');
  const [timeline, setTimeline] = useState('');
  const [description, setDescription] = useState('');
  const [attachments, setAttachments] = useState(null)
  const [buttonDisabled, setButtonDisabled] = useState(true)
 
  console.log(user)

  const nextTab = (e) => {
    e.preventDefault();
    // increment the tabOpen counter
    setTabOpen(tabOpen + 1);
  };

useEffect(() => {
  if(jobTitle && education && pages && spaces && citation && topic && description && attachments && timeline){  
    setButtonDisabled(false)
  }
}, [jobTitle, education, pages, spaces, citation, topic, description, attachments, timeline])

  const handleUpload = () => {
    const files = attachments ? [...attachments] : [];
    if(jobTitle && education && pages && spaces && citation && topic && description){  
      const data = new FormData();
      files.forEach((file) => {
        data.append(`files.attachments`, file, file.name);
      });
      const otherData = {
        jobTitle,
        education,
        pages,
        spaces,
        citation,
        topic,
        timeline, 
        description
      }
      data.append('data', JSON.stringify(otherData))
      uploadOrder(data)
    }
  }

  const previousTab = (e) => {
    e.preventDefault();
    // decrement the tabOpen counter
    setTabOpen(tabOpen - 1);
  };

  return (
    <div className='flex flex-col md:w-[700px] lg:w-[1013px] justify-center font-roboto'>
      <div className='Header flex flex-col mx-3 my-3'>
        <p className='justify-start text-[21px] leading-[24px] font-normal text-[#333269]'>
          Hello, {user?.username}
        </p>
        <button
          className={buttonDisabled ? 
            "self-end bg-[#808080] text-white  text-lg px-6 py-3 rounded shadow  cursor-not-allowed":
            "self-end bg-[#275350] text-white active:bg-[#5d817f]   text-lg px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none my-3 ease-linear transition-all duration-150"}
          type='button'
          disabled={buttonDisabled}
          onClick={handleUpload}>
          Upload Job
        </button>
      </div>
      <div className='form flex-col bg-white  shadow-2xl min-h-[670px] mx-4'>
        <div className='form__header h-24 border-2'>
          <ul className='flex list-none  pt-3 pb-4 flex-row justify-evenly items-center  h-full'>
            <li className='mx-2'>
              <a
                className={`${
                  tabOpen === 1
                    ? "border-b-2 border-[#275350] text-[#275350]"
                    : ""
                }   font-normal text-xl p-1 opacity-80 `}
                href='#OrderDetails'
                role='tablist'
                data-toggle='tab'
                onClick={(e) => {
                  e.preventDefault();
                  setTabOpen(1);
                }}>
                Order Details
              </a>
            </li>
            <li>
              <a
                className={`${
                  tabOpen === 2
                    ? "border-b-2 border-[#275350] text-[#275350]"
                    : ""
                }   font-normal text-xl p-1 opacity-80`}
                href='#OrderDetails'
                role='tablist'
                data-toggle='tab'
                onClick={(e) => {
                  e.preventDefault();
                  setTabOpen(2);
                }}>
                Order Description
              </a>
            </li>
            <li>
              <a
                className={`${
                  tabOpen === 3
                    ? "border-b-2 border-[#275350] text-[#275350]"
                    : ""
                }   font-normal text-xl p-1 opacity-80`}
                href='#OrderDetails'
                role='tablist'
                data-toggle='tab'
                onClick={(e) => {
                  e.preventDefault();
                  setTabOpen(3);
                }}>
                Order Attachments
              </a>
            </li>
            <li>
              <a
                className={`${
                  tabOpen === 4
                    ? "border-b-2 border-[#275350] text-[#275350]"
                    : ""
                }   font-normal text-xl p-1 opacity-80`}
                href='#OrderDetails'
                role='tablist'
                data-toggle='tab'
                onClick={(e) => {
                  e.preventDefault();
                  setTabOpen(4);
                }}>
                Order Payment
              </a>
            </li>
          </ul>
        </div>
        <div className='form__details'>
          {tabOpen === 1 && <OrderDetails
           nextTab={nextTab} 
           jobTitle={jobTitle}
           setJobTitle={setJobTitle}
           education={education}
           setEducation={setEducation}
           pages={pages}
           setPages={setPages}
           setCitation={setCitation}
           setSpaces={setSpaces}

           />}
          {tabOpen === 2 && (
            <OrderDescription previousTab={previousTab} nextTab={nextTab} setDescription={setDescription} description={description} topic={topic} setTopic={setTopic} timeline={timeline} setTimeline={setTimeline}/>
          )}
          {tabOpen === 3 && (
            <OrderAttachments previousTab={previousTab} nextTab={nextTab} setAttachments={setAttachments} attachments={attachments}/>
          )}
          {tabOpen === 4 && (
            <div className='form__details grid grid-flow-col place-items-center'>
              <OrderBill previousTab={previousTab} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
const mapDispatchToProps = {
  uploadOrder: orderActions.uploadOrder
}

const mapStateToProps = (state) => ({
  user: state.authentication.user
})

export default connect(mapStateToProps, mapDispatchToProps) (CreateOrder);