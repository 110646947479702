import { useLocation } from "react-router-dom";

const Topbar = ({ showChat, showProfile }) => {
  const location = useLocation();
  const path = location.pathname;
  return (
    <div className=' bg-white shadow-lg '>
      <div className='container md:h-20 flex mx-auto'>
        {" "}
        <div class=' flex flex-1 rounded border-r '>
          <button class='flex items-center justify-center px-4 '>
            <svg
              class='w-6 h-6 text-gray-600'
              fill='currentColor'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 24 24'>
              <path d='M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z'></path>
            </svg>
          </button>
          <input
            type='text'
            className='px-4 py-2 flex-1 focus:outline-none'
            placeholder='Search...'
          />
        </div>
        <div className='topbar__left flex '>
          <div className='icons flex items-center px-3'>
            <div
              className={`${
                path === "/client-account-payment" ? "hidden" : "px-2"
              } `}>
              <button onClick={showChat}>
                {" "}
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  class='h-6 w-6'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'>
                  <path
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    d='M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z'
                  />
                </svg>
              </button>
            </div>
            <div
              className={`${
                path === "/client-account-payment" ? "hidden" : "px-2"
              } `}>
              <a href='/'>
                {" "}
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  class='h-5 w-5'
                  viewBox='0 0 20 20'
                  fill='currentColor'>
                  <path d='M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z' />
                </svg>
              </a>
            </div>
          </div>
          <div className='avatar flex -space-x-2 overflow-hidden items-center mx-1'>
            <img
              className='inline-block h-10 w-10 rounded-full ring-2 ring-white'
              src='https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
              alt=''
            />
          </div>
          <div className='flex flex-col items-center justify-center'>
            <p className='text-base'>John Doe</p>
            <p className='text-sm'>Active</p>
          </div>
          <div
            className={`${
              path === "/client-account-payment"
                ? "hidden"
                : "bars flex items-center mx-3"
            } `}>
            <button className='' onClick={showProfile}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                class='h-6 w-6'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'>
                <path
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2'
                  d='M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z'
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
