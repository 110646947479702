const Error = ({title, message}) => {
return (
    <div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
        <div class="flex items-center"> 
            <strong class="font-bold">{title}</strong>
            <span class="block sm:inline">  {message}</span>
        </div>
    </div>
)
}
export default Error