import { useState } from "react";
import Chat from "../Chat/Chat";
import CreateOrder from "../CreateOrder/CreateOrder";
import Profile from "../Profile/Profile";
import MyOrders from "./MyOrders";
import NavLink from "./NavLink";
import Topbar from "./Topbar";

export const ClientAccount = () => {
  const [showComponent, setShowComponent] = useState(2);
  const [showSidebar, setShowSidebar] = useState(false);

  const showChat = (e) => {
    e.preventDefault();
    setShowComponent(3);
  };

  const showProfile = (e) => {
    e.preventDefault();
    setShowComponent(4);
  };

  const showNewOrder = (e) => {
    console.log("clicked")
    e.preventDefault();
    setShowComponent(1);
  }
  return (
    <div class='relative md:flex  overflow-hidden h-full min-h-screen font-roboto'>
      {/*mobile menu bar  */}
      <div class='bg-[#275350] text-gray-100 flex justify-between md:hidden'>
        {/* logo  */}
        <a href='/' class='block p-4 text-white font-bold'>
          Online Platform
        </a>

        {/*mobile menu button  */}
        <button
          class='mobile-menu-button p-4 focus:outline-none focus:bg-[#275350]'
          onClick={() => {
            setShowSidebar(!showSidebar);
            console.log(showSidebar);
          }}>
          <svg
            class='h-5 w-5'
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            stroke='currentColor'>
            <path
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              d='M4 6h16M4 12h16M4 18h16'
            />
          </svg>
        </button>
      </div>

      {/* sidebar  */}
      <div
        className={`sidebar absolute md:relative h-auto min-h-screen max-h-full bg-[#275350] inset-y-0 transform md:transform-none md:opacity-100 duration-300   z-10 w-72 ${
          showSidebar
            ? "translate-x-0 ease-in opacity-100"
            : "-translate-x-full ease-out opacity-0"
        }`}>
        {/*logo  */}
        <a
          href='/'
          class='text-white flex items-center space-x-2 px-4 font-roboto bg-transparent my-10 bg-clip-text bg-gradient-to-br from-[#FFFFFF] to-[#DEDDFF]'>
          <span class='text-4xl font-extrabold'>Online Platform</span>
        </a>

        {/* nav */}
        <nav className='grid grid-flow-row gap-4'>
          <div className='flex mx-auto items-center'>
            <button onClick={() => setShowComponent(1)}>
              <svg
                width='16'
                height='15'
                viewBox='0 0 16 15'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <mask id='path-1-inside-1' fill='white'>
                  <rect x='8.44336' width='6.56706' height='6.5625' rx='1' />
                </mask>
                <rect
                  x='8.44336'
                  width='6.56706'
                  height='6.5625'
                  rx='1'
                  stroke='#C0BEFF'
                  stroke-width='3'
                  mask='url(#path-1-inside-1)'
                />
                <mask id='path-2-inside-2' fill='white'>
                  <rect
                    x='8.44336'
                    y='7.5'
                    width='6.56706'
                    height='6.5625'
                    rx='1'
                  />
                </mask>
                <rect
                  x='8.44336'
                  y='7.5'
                  width='6.56706'
                  height='6.5625'
                  rx='1'
                  stroke='#C0BEFF'
                  stroke-width='3'
                  mask='url(#path-2-inside-2)'
                />
                <mask id='path-3-inside-3' fill='white'>
                  <rect y='7.5' width='6.56706' height='6.5625' rx='1' />
                </mask>
                <rect
                  y='7.5'
                  width='6.56706'
                  height='6.5625'
                  rx='1'
                  stroke='#C0BEFF'
                  stroke-width='3'
                  mask='url(#path-3-inside-3)'
                />
                <mask id='path-4-inside-4' fill='white'>
                  <rect width='6.56706' height='6.5625' rx='1' />
                </mask>
                <rect
                  width='6.56706'
                  height='6.5625'
                  rx='1'
                  stroke='#C0BEFF'
                  stroke-width='3'
                  mask='url(#path-4-inside-4)'
                />
              </svg>
            </button>
            <NavLink
              link=''
              text='New Order'
              onClick={() => setShowComponent(1)}
            />
          </div>
          <div className='flex mx-auto items-center'>
            <button onClick={() => setShowComponent(2)}>
              <svg
                width='16'
                height='15'
                viewBox='0 0 16 15'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <mask id='path-1-inside-1' fill='white'>
                  <rect x='8.44336' width='6.56706' height='6.5625' rx='1' />
                </mask>
                <rect
                  x='8.44336'
                  width='6.56706'
                  height='6.5625'
                  rx='1'
                  stroke='white'
                  stroke-width='3'
                  mask='url(#path-1-inside-1)'
                />
                <mask id='path-2-inside-2' fill='white'>
                  <rect
                    x='8.44336'
                    y='7.5'
                    width='6.56706'
                    height='6.5625'
                    rx='1'
                  />
                </mask>
                <rect
                  x='8.44336'
                  y='7.5'
                  width='6.56706'
                  height='6.5625'
                  rx='1'
                  stroke='white'
                  stroke-width='3'
                  mask='url(#path-2-inside-2)'
                />
                <mask id='path-3-inside-3' fill='white'>
                  <rect y='7.5' width='6.56706' height='6.5625' rx='1' />
                </mask>
                <rect
                  y='7.5'
                  width='6.56706'
                  height='6.5625'
                  rx='1'
                  stroke='white'
                  stroke-width='3'
                  mask='url(#path-3-inside-3)'
                />
                <mask id='path-4-inside-4' fill='white'>
                  <rect width='6.56706' height='6.5625' rx='1' />
                </mask>
                <rect
                  width='6.56706'
                  height='6.5625'
                  rx='1'
                  stroke='white'
                  stroke-width='3'
                  mask='url(#path-4-inside-4)'
                />
              </svg>
            </button>
            <NavLink text='My Orders' onClick={() => setShowComponent(2)} />
          </div>
          <div className='flex mx-auto items-center'>
            <a href='/'>
              <svg
                width='24'
                height='23'
                viewBox='0 0 24 23'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M16.5427 1.91663C16.889 1.91663 17.2319 1.98479 17.5519 2.11724C17.8719 2.24968 18.1626 2.4438 18.4075 2.68852C18.6524 2.93324 18.8466 3.22377 18.9792 3.54351C19.1117 3.86326 19.1799 4.20595 19.1799 4.55204V18.4479C19.1799 18.794 19.1117 19.1367 18.9792 19.4564C18.8466 19.7761 18.6524 20.0667 18.4075 20.3114C18.1626 20.5561 17.8719 20.7502 17.5519 20.8827C17.2319 21.0151 16.889 21.0833 16.5427 21.0833H6.47318C6.12678 21.0833 5.78376 21.0151 5.46373 20.8826C5.1437 20.7501 4.85293 20.5559 4.60803 20.3111C4.36313 20.0662 4.16889 19.7756 4.03641 19.4557C3.90393 19.1359 3.83581 18.7931 3.83594 18.4469V4.55204C3.83594 3.34454 4.64725 2.32871 5.75394 2.01533V3.59371C5.4624 3.81221 5.27444 4.15913 5.27444 4.55204V18.446C5.27444 19.1072 5.81148 19.6439 6.47318 19.6439H16.5427C17.2044 19.6439 17.7414 19.1072 17.7414 18.446V4.55204C17.7414 3.89079 17.2044 3.35413 16.5427 3.35413H14.3849V1.91663H16.5427ZM13.4259 1.91663V9.7165C13.4259 10.4324 12.6587 10.7007 12.1888 10.4487L12.1102 10.3988L10.0713 9.16738L8.07855 10.3663C7.59905 10.7113 6.80308 10.5004 6.71965 9.8315L6.71293 9.71746V1.91663H13.4259ZM11.9874 3.35413H8.15143V8.647L9.65994 7.73754C9.78012 7.6737 9.91342 7.6384 10.0495 7.63442C10.1855 7.63043 10.3207 7.65785 10.4444 7.71454L11.9884 8.64796V3.35413H11.9874Z'
                  fill='#C0BEFF'
                />
              </svg>
            </a>
            <NavLink link='' text='In Progress' />
          </div>
          <div className='flex mx-auto items-center'>
            <a href='/'>
              <svg
                width='19'
                height='18'
                viewBox='0 0 19 18'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M11.5706 9.16082V15.2031C11.5706 16.0574 10.9004 16.75 10.0596 16.75H3.51423C2.67166 16.75 2.00146 16.0574 2.00146 15.2031V5.92188C2.00387 5.51236 2.16772 5.1203 2.4575 4.83072C2.74728 4.54114 3.13961 4.3774 3.54941 4.375H6.86062C6.97523 4.37517 7.08866 4.39814 7.1943 4.44257C7.29994 4.48699 7.39568 4.55198 7.47593 4.63375L11.3159 8.53609C11.4793 8.70305 11.5707 8.92731 11.5706 9.16082V9.16082Z'
                  stroke='#C0BEFF'
                  stroke-width='1.25'
                  stroke-linejoin='round'
                />
                <path
                  d='M6.78589 4.375V8.17188C6.78899 8.43198 6.89377 8.68056 7.07784 8.8645C7.2619 9.04844 7.51066 9.15315 7.77095 9.15625H11.5705'
                  stroke='#C0BEFF'
                  stroke-width='1.25'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
                <path
                  d='M5.94165 4.375V2.54688C5.94405 2.13736 6.10791 1.7453 6.39769 1.45572C6.68746 1.16614 7.0798 1.0024 7.4896 1H10.7966C10.9118 1.00016 11.0259 1.02311 11.1322 1.06751C11.2385 1.11192 11.335 1.17692 11.4161 1.25875L15.2561 5.16109C15.4193 5.32626 15.5109 5.54909 15.5108 5.78125V11.8281C15.5108 12.6824 14.8406 13.375 13.9998 13.375H11.852'
                  stroke='#C0BEFF'
                  stroke-width='1.25'
                  stroke-linejoin='round'
                />
                <path
                  d='M10.7261 1V4.79688C10.7292 5.05698 10.834 5.30556 11.018 5.4895C11.2021 5.67344 11.4508 5.77815 11.7111 5.78125H15.5106'
                  stroke='#C0BEFF'
                  stroke-width='1.25'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>
            </a>
            <NavLink link='' text='Completed' />
          </div>
          <div className='flex mx-auto items-center'>
            <a href='/'>
              <svg
                width='16'
                height='14'
                viewBox='0 0 16 14'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M10.2577 10.5C11.5012 10.5 12.5092 9.49264 12.5092 8.25C12.5092 7.00736 11.5012 6 10.2577 6C9.01416 6 8.0061 7.00736 8.0061 8.25C8.0061 9.49264 9.01416 10.5 10.2577 10.5Z'
                  stroke='#C0BEFF'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
                <path
                  d='M8.75659 10.125V13.5L10.2576 12.375L11.7587 13.5V10.125'
                  stroke='#C0BEFF'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
                <path
                  d='M6.50511 11.25H2.75251C2.35441 11.25 1.97261 11.092 1.69111 10.8107C1.40961 10.5294 1.25146 10.1478 1.25146 9.75V2.25C1.25146 1.425 1.92693 0.75 2.75251 0.75H13.2598C13.6579 0.75 14.0397 0.908035 14.3212 1.18934C14.6027 1.47064 14.7608 1.85218 14.7608 2.25V9.75C14.7606 10.013 14.6911 10.2714 14.5594 10.4991C14.4276 10.7269 14.2383 10.916 14.0103 11.0475'
                  stroke='#C0BEFF'
                  stroke-width='1.25'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
                <path
                  d='M3.50293 3.75H12.5092'
                  stroke='#C0BEFF'
                  stroke-width='1.25'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
                <path
                  d='M3.50293 6H5.75449'
                  stroke='#C0BEFF'
                  stroke-width='1.25'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
                <path
                  d='M3.50293 8.25H5.00397'
                  stroke='#C0BEFF'
                  stroke-width='1.25'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>
            </a>
            <NavLink link='' text='Transcripts' />
          </div>
          <div className='flex mx-auto items-center'>
            <a href='/'>
              <svg
                width='22'
                height='22'
                viewBox='0 0 22 22'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M16.7659 6.48826C16.5736 6.19991 16.303 5.97241 15.986 5.83245C15.6689 5.69248 15.3185 5.64586 14.9758 5.69806C14.4259 5.7764 13.8656 5.66942 13.3832 5.39399C12.9008 5.11857 12.5238 4.69037 12.3118 4.17697C12.0402 3.51997 11.4322 3.06966 10.7219 3.034C10.4614 3.02089 10.2004 3.02089 9.9399 3.034C9.2249 3.07181 8.61431 3.52771 8.34576 4.19115C8.13798 4.70513 7.76525 5.13547 7.28618 5.41448C6.80711 5.69349 6.24889 5.80534 5.69931 5.73244C4.9899 5.63833 4.29209 5.94427 3.90666 6.54884C3.75346 6.78852 3.61374 7.03655 3.48814 7.29177C3.18306 7.9131 3.30123 8.6281 3.72748 9.17337C4.06987 9.61059 4.25688 10.1494 4.25898 10.7047C4.26108 11.2601 4.07815 11.8003 3.73908 12.2401C3.31541 12.7905 3.20197 13.5072 3.51178 14.1307C3.58053 14.2682 3.654 14.4044 3.7322 14.5393C3.79952 14.6539 3.86942 14.7665 3.94189 14.877C4.13424 15.1653 4.40482 15.3928 4.72188 15.5328C5.03894 15.6727 5.38934 15.7194 5.73197 15.6672C6.28191 15.5887 6.84233 15.6957 7.32475 15.9711C7.80717 16.2466 8.1841 16.6748 8.39603 17.1883C8.6676 17.8453 9.27603 18.2956 9.98588 18.3309C10.2464 18.3444 10.5074 18.3444 10.7679 18.3309C11.4829 18.2935 12.0935 17.8376 12.362 17.1742C12.5697 16.6602 12.9423 16.2298 13.4213 15.9508C13.9003 15.6717 14.4585 15.5599 15.0081 15.6329C15.7179 15.727 16.4157 15.421 16.8012 14.8165C16.9542 14.5766 17.0938 14.3285 17.2192 14.0731C17.5247 13.4522 17.4066 12.7372 16.9799 12.1919C16.6377 11.7545 16.4508 11.2156 16.4488 10.6603C16.4468 10.1049 16.6297 9.5647 16.9687 9.12482C17.3924 8.57482 17.5058 7.8581 17.1956 7.23634C17.1274 7.09884 17.0539 6.96263 16.9752 6.82771C16.9081 6.71227 16.8384 6.59912 16.7659 6.48826Z'
                  stroke='#C0BEFF'
                  stroke-width='1.32624'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
                <path
                  d='M10.3546 12.7462C11.4942 12.7462 12.418 11.8224 12.418 10.6829C12.418 9.5433 11.4942 8.61951 10.3546 8.61951C9.21506 8.61951 8.29126 9.5433 8.29126 10.6829C8.29126 11.8224 9.21506 12.7462 10.3546 12.7462Z'
                  stroke='#C0BEFF'
                  stroke-width='1.32624'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>
            </a>
            <NavLink link='' text='Settings' />
          </div>
        </nav>
      </div>
      {/* content */}
      <div class='flex-1 bg-[#E5E5E5]  h-full  w-full min-h-screen'>
        <Topbar showChat={showChat} showProfile={showProfile} />
        {showComponent === 1 && (
          <div className='flex justify-center items-center w-full h-full min-h-screen'>
            <CreateOrder />
          </div>
        )}
        {showComponent === 2 && (
          <div className='mb-2  m-4  w-full h-full '>
            <MyOrders showNewOrder={showNewOrder} />
          </div>
        )}
        {showComponent === 3 && (
          <div className='w-full  h-full min-h-screen'>
            <Chat />
          </div>
        )}
        {showComponent === 4 && (
          <div className='w-full  h-full min-h-screen'>
            <Profile />
          </div>
        )}
      </div>
    </div>
  );
};
