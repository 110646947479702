import { orderConstants } from "./actionCreators";
import {ordersService } from "../API/orders"

export const orderActions = {
    uploadOrder,
    getAllOrders
}

function uploadOrder(data) {
    return (dispatch) => {
      dispatch(request());
      ordersService.uploadOrder(data).then(
        (user) => {
            
          dispatch(success(user));
        },
        (error) => {
          dispatch(failure(error.message));
        },
      );
    };
  
    function request() {
      return { type: orderConstants.UPLOAD_REQUEST };
    }
  
    function success(user) {
      return { type: orderConstants.UPLOAD_SUCCESS, user };
    }
    function failure(error) {
      return { type: orderConstants.UPLOAD_FAILURE, error };
    }
  }

  function getAllOrders(data) {
    return (dispatch) => {
      dispatch(request());
      ordersService.getAllOrders(data).then(
        (orders) => {
            
          dispatch(success(orders));
        },
        (error) => {
          dispatch(failure(error.message));
        },
      );
    };
  
    function request() {
      return { type: orderConstants.GET_REQUEST };
    }
  
    function success(orders) {
      return { type: orderConstants.GET_SUCCESS, orders };
    }
    function failure(error) {
      return { type: orderConstants.GET_FAILURE, error };
    }
  }
  