import { userConstants } from "../actions/actionCreators";

const initialState = {
    loading: false,
    error: ''
}

export function registration(state = initialState, action) {
  switch (action.type) {
    case userConstants.REGISTER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.REGISTER_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case userConstants.REGISTER_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}
