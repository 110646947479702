import shape from "../../images/shape.svg";

const ProcessCard = ({ svg, title }) => {
  return (
    <div className="ProcessCard font-roboto mx-5 ">
      <div className="object-contain w-8 h-8">
        <img className="" src={svg} alt="" />
      </div>
      <h3 className="processCard__title text-lg  leading-7 font-extrabold text-[#091133]">
        {title}
      </h3>
    </div>
  );
};

const ProcessInfo = () => {
  return (
    <div className="processInfo__container mt-8 font-roboto ">
      <div className="processInfo__text flex flex-col  mx-7 py-3">
        <h2 className="font-normal text-5xl leading-10 text-[#275350] self-center mb-3">
          Why Choose Us
        </h2>
      </div>

      <div className="container  md:mx-auto mt-4">
        <div className=" lg:grid sm:block md:block grid xs:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <div class="max-w-sm rounded overflow-hidden shadow-lg p-4  font-roboto transform transition duration-500 hover:scale-95 cursor-pointer">
            <ProcessCard title="Step 1" svg={shape} />
            <div>
              <p className="processCard__text text-[#275350] leading-6 text-lg font-normal py-2 ">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Debitis amet ex veniam voluptas dolorum, cum, minus accusamus
                temporibus suscipit molestias architecto quae nemo, nostrum quos
                consectetur rem fuga. Excepturi, illo?
              </p>
            </div>
          </div>

          <div class="max-w-sm rounded overflow-hidden shadow-lg p-4  font-roboto transform transition duration-500 hover:scale-95 cursor-pointer">
            <ProcessCard title="Step 2" svg={shape} />
            <div>
              <p className="processCard__text text-[#275350] leading-6 text-lg font-normal py-2 ">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Debitis amet ex veniam voluptas dolorum, cum, minus accusamus
                temporibus suscipit molestias architecto quae nemo, nostrum quos
                consectetur rem fuga. Excepturi, illo?
              </p>
            </div>
          </div>

          <div class="max-w-sm rounded overflow-hidden shadow-lg p-4  font-roboto transform transition duration-500 hover:scale-95 cursor-pointer">
            <ProcessCard title="Step 3" svg={shape} />
            <div>
              <p className="processCard__text text-[#275350] leading-6 text-lg font-normal py-2 ">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Debitis amet ex veniam voluptas dolorum, cum, minus accusamus
                temporibus suscipit molestias architecto quae nemo, nostrum quos
                consectetur rem fuga. Excepturi, illo?
              </p>
            </div>
          </div>

          <div class="max-w-sm rounded overflow-hidden shadow-lg p-4  font-roboto transform transition duration-500 hover:scale-95 cursor-pointer">
            <ProcessCard title="Step 4" svg={shape} />
            <div>
              <p className="processCard__text text-[#275350] leading-6 text-lg font-normal py-2 ">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Debitis amet ex veniam voluptas dolorum, cum, minus accusamus
                temporibus suscipit molestias architecto quae nemo, nostrum quos
                consectetur rem fuga. Excepturi, illo?
              </p>
            </div>
          </div>

          <div class="max-w-sm rounded overflow-hidden shadow-lg p-4  font-roboto transform transition duration-500 hover:scale-95 cursor-pointer">
            <ProcessCard title="Step 4" svg={shape} />
            <div>
              <p className="processCard__text text-[#275350] leading-6 text-lg font-normal py-2 ">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Debitis amet ex veniam voluptas dolorum, cum, minus accusamus
                temporibus suscipit molestias architecto quae nemo, nostrum quos
                consectetur rem fuga. Excepturi, illo?
              </p>
            </div>
          </div>

          <div class="max-w-sm rounded overflow-hidden shadow-lg p-4  font-roboto transform transition duration-500 hover:scale-95 cursor-pointer">
            <ProcessCard title="Step 4" svg={shape} />
            <div>
              <p className="processCard__text text-[#275350] leading-6 text-lg font-normal py-2 ">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Debitis amet ex veniam voluptas dolorum, cum, minus accusamus
                temporibus suscipit molestias architecto quae nemo, nostrum quos
                consectetur rem fuga. Excepturi, illo?
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProcessInfo;
