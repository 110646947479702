import { userConstants } from "./actionCreators";
import {loginService} from "../API/authentication"

export const userActions = {
    login,
    getUSer
}

function getUSer () {
  return (dispatch) => {
    dispatch(request());
    loginService.getUser().then(
      (user) => {

        dispatch(success(user));
      //   history.push("/");
      },
      (error) => {
        localStorage.removeItem('token');
        dispatch(failure(error.message));
      },
    );
  };

  function request() {
    return { type: userConstants.GET_USER_REQUEST };
  }

  function success(user) {
    return { type: userConstants.GET_USER_SUCCESS, user };
  }

  function failure(error) {
    return { type: userConstants.GET_USER_FAILURE, error };
  }
}


function login(email, password) {
    return (dispatch) => {
      dispatch(request());
      loginService.login(email, password).then(
        (user) => {

          dispatch(success(user));
        //   history.push("/");
        },
        (error) => {
          dispatch(failure(error.message));
        },
      );
    };
  
    function request() {
      return { type: userConstants.LOGIN_REQUEST };
    }
  
    function success(user) {
      return { type: userConstants.LOGIN_SUCCESS, user };
    }
  
    function failure(error) {
      return { type: userConstants.LOGIN_FAILURE, error };
    }
  }
  