import logo from "../../images/logo.svg";
import "./Navbar.css";
import { useState } from "react";
import { Link, useNavigate} from "react-router-dom";
import { FaUnlockAlt, FaUserEdit } from "react-icons/fa"


export default function Navbar({ fixed }) {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <nav className='flex flex-wrap items-center justify-between px-2 py-3 bg-teal-500  bg-[#275350] bg-opacity-0 h-full sticky font-roboto'>
        <div className='container px-4 mx-auto flex flex-wrap items-center justify-between'>
          <div className='w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start'>
            {/* Logo */}
            <div>
              <a href='/' className=' flex space-x-3 mx-4'>
                {" "}
                <img src={logo} alt='logo' />
                <div className='flex flex-col'>
                  <span className="text-yellow-500 text-3xl"> Essay Genius</span>
                  <span className="text-white text-xl">Master in Academic writing</span>
                </div>
              </a>
            </div>
            <button
              className='text-white cursor-pointer text-xl leading-none px-3 py-1 rounded  block lg:hidden outline-none focus:outline-none transition-all'
              type='button'
              onClick={() => setNavbarOpen(!navbarOpen)}>
              {navbarOpen ? (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-6 w-6 animate-bounce'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'>
                  <path
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    d='M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z'
                  />
                </svg>
              ) : (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-6 w-6 fill-current text-white animate-bounce'
                  viewBox='0 0 24 24'
                  stroke='currentColor'>
                  <path
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    d='M4 6h16M4 12h16M4 18h16'
                  />
                </svg>
              )}
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center " +
              (navbarOpen ? " flex " : " hidden")
            }
            id='example-navbar-danger'>
            <ul className='flex flex-col lg:flex-row list-none lg:ml-auto py-3 '>

              <div className='flex justify-between align-middle space-x-2'>
                <div className=' flex justify-between align-middle  hover:bg-yellow-500 ' >               
                <div className="flex justify-between items-center space-x-2">
                  <FaUnlockAlt color="white"/>
                </div>
                  <Link to='/login'className="m-0">
                    <button class='bg-transparent   text-white font-bold py-2 px-2 rounded outline-0 ' onClick={() => navigate('/login')}>
                      Sign In
                    </button>
                  </Link>
                </div>
                <div className='flex justify-between align-middle  hover:bg-yellow-500 '>
                <div className="flex justify-between items-center space-x-2">
                  <FaUserEdit color="white"/>
                </div>
                  <Link to='/register' className="m-0">
                    <button class='bg-transparent  text-white font-bold py-2 px-2 rounded outline-0' onClick={() => navigate('/register')}>
                      Register
                    </button>
                  </Link>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
