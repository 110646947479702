import { combineReducers } from 'redux';
import {authentication} from './authentication'
import { registration } from './register'
import {orders} from './order'



const rootReducer = combineReducers({
    authentication,
    registration,
    orders

});

export default rootReducer;