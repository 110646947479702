const Chat = () => {
  return (
    <div className='flex flex-col lg:flex-row'>
      <div className='leftContainer flex flex-col justify-center items-center w-full lg:w-2/5 md:h-[700px]  lg:border-r lg:border-gray-400 mx-6 mt-4'>
        <h1 className='font-gluten font-medium text-2xl leading-6 self-center mt-5'>
          Chat
        </h1>
        <div className='w-[300px] h-[440px] rounded-3xl bg-white mx-auto'>
          <div className='name m-4 border-b-2 border-gray-400'>
            <h3 className='font-bold font-poppins text-xl leading-8'>Admin</h3>
          </div>
          <div className='details'>
            <MessageBox />
          </div>
        </div>
      </div>
      <div className='rightContainer w-full lg:w-3/5 space-x-4 '>
        <div className='chatContainer w-full grid grid-flow-col-dense place-items-center p-2 mt-3'>
          <div className='w-full lg:w-[600px] h-full lg:h-[800px]  rounded-3xl bg-white flex flex-col shadow-lg '>
            <div className='topIcons flex justify-between h-12 items-center p-3'>
              <div className='senderInfo flex flex-col mx-8 '>
                <p className='font-poppins font-bold text-lg leading-5'>
                  kiran
                </p>
                <p className='font-poppins text-sm overflow-ellipsis '>
                  online
                </p>
              </div>
              <div className='senderIcons  flex justify-evenly w-[150px] my-3'>
                <div className='phoneIcon'>
                  <svg
                    width='21'
                    height='20'
                    viewBox='0 0 21 20'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M18.061 13.6386L15.2331 13.3158C14.9005 13.2767 14.5635 13.3135 14.2473 13.4234C13.931 13.5334 13.6438 13.7135 13.4072 13.9504L11.3586 15.999C8.19797 14.3915 5.629 11.8225 4.02155 8.66193L6.08127 6.60221C6.56001 6.12347 6.79381 5.45546 6.71588 4.77631L6.39301 1.97065C6.32988 1.42752 6.06923 0.926578 5.66068 0.563191C5.25212 0.199803 4.7242 -0.000648259 4.17742 1.57505e-06H2.25131C0.99322 1.57505e-06 -0.0533374 1.04656 0.0245977 2.30465C0.614677 11.8127 8.21891 19.4058 17.7159 19.9959C18.974 20.0738 20.0205 19.0273 20.0205 17.7692V15.8431C20.0316 14.7186 19.1855 13.7722 18.061 13.6386Z'
                      fill='#0057FF'
                    />
                  </svg>
                </div>

                <div className='videoIcon'>
                  <svg
                    width='29'
                    height='20'
                    viewBox='0 0 29 20'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M22.8777 2.85714C22.8777 1.28143 21.5962 0 20.0205 0H2.87765C1.30194 0 0.0205078 1.28143 0.0205078 2.85714V17.1429C0.0205078 18.7186 1.30194 20 2.87765 20H20.0205C21.5962 20 22.8777 18.7186 22.8777 17.1429V12.3814L28.5919 17.1429V2.85714L22.8777 7.61857V2.85714Z'
                      fill='#0057FF'
                    />
                  </svg>
                </div>
                <div className='optionsIcon'>
                  <svg
                    width='6'
                    height='25'
                    viewBox='0 0 6 25'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M2.52051 5C3.90122 5 5.02051 3.88071 5.02051 2.5C5.02051 1.11929 3.90122 0 2.52051 0C1.1398 0 0.0205078 1.11929 0.0205078 2.5C0.0205078 3.88071 1.1398 5 2.52051 5Z'
                      fill='#0057FF'
                    />
                    <path
                      d='M2.52051 15C3.90122 15 5.02051 13.8807 5.02051 12.5C5.02051 11.1193 3.90122 10 2.52051 10C1.1398 10 0.0205078 11.1193 0.0205078 12.5C0.0205078 13.8807 1.1398 15 2.52051 15Z'
                      fill='#0057FF'
                    />
                    <path
                      d='M2.52051 25C3.90122 25 5.02051 23.8807 5.02051 22.5C5.02051 21.1193 3.90122 20 2.52051 20C1.1398 20 0.0205078 21.1193 0.0205078 22.5C0.0205078 23.8807 1.1398 25 2.52051 25Z'
                      fill='#0057FF'
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className='chatInputAndDisplay flex flex-col flex-1'>
              <div className='chatDisplay flex-1 flex flex-col mt-20 mb-16 space-y-4 p-3 overflow-y-auto'>
                <ChatMessageLeft />
                <ChatMessageRight />
              </div>
              <div className='chatInput border-t border-gray-300'>
                <div class='flex flex-wrap items-stretch w-full relative h-15 bg-white items-center rounded mb-4'>
                  <div class='flex -mr-px justify-center w-15 p-4'>
                    <button>
                      {" "}
                      <span class='flex items-center leading-normal bg-white rounded rounded-r-none text-xl px-3 whitespace-no-wrap text-gray-600'>
                        <svg
                          width='14'
                          height='13'
                          viewBox='0 0 14 13'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'>
                          <path
                            d='M0 2.5C0 1.83696 0.263392 1.20107 0.732233 0.732233C1.20107 0.263392 1.83696 0 2.5 0H6.5C7.16304 0 7.79893 0.263392 8.26777 0.732233C8.73661 1.20107 9 1.83696 9 2.5V3H8V2.5C8 2.10218 7.84196 1.72064 7.56066 1.43934C7.27936 1.15804 6.89782 1 6.5 1H2.5C2.10218 1 1.72064 1.15804 1.43934 1.43934C1.15804 1.72064 1 2.10218 1 2.5V6.5C1 6.89782 1.15804 7.27936 1.43934 7.56066C1.72064 7.84196 2.10218 8 2.5 8H8.085C8.20295 7.66639 8.43503 7.38522 8.74024 7.20618C9.04545 7.02715 9.40412 6.96177 9.75287 7.0216C10.1016 7.08144 10.418 7.26264 10.6461 7.53317C10.8741 7.8037 10.9992 8.14616 10.9992 8.5C10.9992 8.85384 10.8741 9.1963 10.6461 9.46683C10.418 9.73736 10.1016 9.91856 9.75287 9.9784C9.40412 10.0382 9.04545 9.97285 8.74024 9.79382C8.43503 9.61478 8.20295 9.33361 8.085 9H2.5C1.83696 9 1.20107 8.73661 0.732233 8.26777C0.263392 7.79893 0 7.16304 0 6.5V2.5ZM5 10V10.5C5 11.163 5.26339 11.7989 5.73223 12.2678C6.20107 12.7366 6.83696 13 7.5 13H11.5C12.163 13 12.7989 12.7366 13.2678 12.2678C13.7366 11.7989 14 11.163 14 10.5V6.5C14 5.83696 13.7366 5.20107 13.2678 4.73223C12.7989 4.26339 12.163 4 11.5 4H5.915C5.79705 3.66639 5.56497 3.38522 5.25976 3.20618C4.95455 3.02715 4.59588 2.96177 4.24713 3.0216C3.89839 3.08144 3.58202 3.26264 3.35395 3.53317C3.12588 3.8037 3.00079 4.14616 3.00079 4.5C3.00079 4.85384 3.12588 5.1963 3.35395 5.46683C3.58202 5.73736 3.89839 5.91856 4.24713 5.9784C4.59588 6.03823 4.95455 5.97285 5.25976 5.79382C5.56497 5.61478 5.79705 5.33361 5.915 5H11.5C11.8978 5 12.2794 5.15804 12.5607 5.43934C12.842 5.72064 13 6.10218 13 6.5V10.5C13 10.8978 12.842 11.2794 12.5607 11.5607C12.2794 11.842 11.8978 12 11.5 12H7.5C7.10218 12 6.72064 11.842 6.43934 11.5607C6.15804 11.2794 6 10.8978 6 10.5V10H5Z'
                            fill='black'
                          />
                        </svg>
                      </span>
                    </button>
                  </div>
                  <input
                    type='text'
                    class='flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border-0 h-10 px-3 relative self-center font-roboto text-xl outline-none'
                    placeholder='enter text'
                  />
                  <div class='flex -mr-px'>
                    <button>
                      {" "}
                      <span class='flex items-center leading-normal bg-white rounded rounded-l-none border-0 px-3 whitespace-no-wrap text-gray-600'>
                        <svg
                          width='15'
                          height='15'
                          viewBox='0 0 15 15'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'>
                          <path
                            d='M7.5 14.0625C5.75952 14.0625 4.09032 13.3711 2.85961 12.1404C1.6289 10.9097 0.9375 9.24048 0.9375 7.5C0.9375 5.75952 1.6289 4.09032 2.85961 2.85961C4.09032 1.6289 5.75952 0.9375 7.5 0.9375C9.24048 0.9375 10.9097 1.6289 12.1404 2.85961C13.3711 4.09032 14.0625 5.75952 14.0625 7.5C14.0625 9.24048 13.3711 10.9097 12.1404 12.1404C10.9097 13.3711 9.24048 14.0625 7.5 14.0625ZM7.5 15C9.48912 15 11.3968 14.2098 12.8033 12.8033C14.2098 11.3968 15 9.48912 15 7.5C15 5.51088 14.2098 3.60322 12.8033 2.1967C11.3968 0.790176 9.48912 0 7.5 0C5.51088 0 3.60322 0.790176 2.1967 2.1967C0.790176 3.60322 0 5.51088 0 7.5C0 9.48912 0.790176 11.3968 2.1967 12.8033C3.60322 14.2098 5.51088 15 7.5 15Z'
                            fill='black'
                            fill-opacity='0.5'
                          />
                          <path
                            d='M11.5602 8.90625C11.6425 9.04877 11.6858 9.21043 11.6858 9.375C11.6858 9.53957 11.6425 9.70123 11.5602 9.84375C11.1489 10.5566 10.5571 11.1485 9.84428 11.5599C9.1315 11.9714 8.32292 12.1878 7.49992 12.1875C6.67707 12.1877 5.86869 11.9711 5.15609 11.5597C4.44349 11.1483 3.8518 10.5565 3.44054 9.84375C3.3583 9.7013 3.31498 9.53972 3.31494 9.37524C3.3149 9.21076 3.35813 9.04916 3.4403 8.90667C3.52247 8.76418 3.64068 8.64581 3.78307 8.56346C3.92545 8.48111 4.087 8.43767 4.25148 8.4375H10.7484C10.9129 8.4375 11.0746 8.48082 11.2171 8.56311C11.3596 8.64539 11.4779 8.76374 11.5602 8.90625ZM6.56242 6.09375C6.56242 6.87 6.14242 6.09375 5.62492 6.09375C5.10742 6.09375 4.68742 6.87 4.68742 6.09375C4.68742 5.3175 5.10742 4.6875 5.62492 4.6875C6.14242 4.6875 6.56242 5.3175 6.56242 6.09375ZM10.3124 6.09375C10.3124 6.87 9.89242 6.09375 9.37492 6.09375C8.85742 6.09375 8.43742 6.87 8.43742 6.09375C8.43742 5.3175 8.85742 4.6875 9.37492 4.6875C9.89242 4.6875 10.3124 5.3175 10.3124 6.09375Z'
                            fill='black'
                            fill-opacity='0.5'
                          />
                        </svg>
                      </span>
                    </button>
                  </div>
                  <div class='flex -mr-px'>
                    <button>
                      <span class='flex items-center leading-normal bg-white rounded rounded-l-none border-0 px-3 whitespace-no-wrap text-gray-600'>
                        <svg
                          width='20'
                          height='15'
                          viewBox='0 0 20 15'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'>
                          <path
                            fill-rule='evenodd'
                            clip-rule='evenodd'
                            d='M13.2368 1.36364H18.4091L19.0909 2.04545V14.3182L18.4091 15H0.681818L0 14.3182V2.04545L0.681818 1.36364H5.85409L7.01727 0.199091L7.5 0H11.5909L12.0736 0.199091L13.2368 1.36364ZM1.36364 13.6364H17.7273V2.72727H12.9545L12.4718 2.52818L11.3086 1.36364H7.78227L6.61909 2.52818L6.13636 2.72727H1.36364V13.6364ZM3.40909 4.09091C3.22826 4.09091 3.05484 4.16274 2.92697 4.29061C2.79911 4.41847 2.72727 4.5919 2.72727 4.77273C2.72727 4.95356 2.79911 5.12698 2.92697 5.25485C3.05484 5.38271 3.22826 5.45455 3.40909 5.45455C3.58992 5.45455 3.76334 5.38271 3.89121 5.25485C4.01907 5.12698 4.09091 4.95356 4.09091 4.77273C4.09091 4.5919 4.01907 4.41847 3.89121 4.29061C3.76334 4.16274 3.58992 4.09091 3.40909 4.09091ZM9.54545 5.45455C10.2688 5.45455 10.9625 5.74188 11.4739 6.25335C11.9854 6.76481 12.2727 7.4585 12.2727 8.18182C12.2727 8.90514 11.9854 9.59883 11.4739 10.1103C10.9625 10.6218 10.2688 10.9091 9.54545 10.9091C8.82214 10.9091 8.12844 10.6218 7.61698 10.1103C7.10552 9.59883 6.81818 8.90514 6.81818 8.18182C6.81818 7.4585 7.10552 6.76481 7.61698 6.25335C8.12844 5.74188 8.82214 5.45455 9.54545 5.45455ZM9.54545 4.09091C8.46048 4.09091 7.41994 4.52191 6.65275 5.28911C5.88555 6.0563 5.45455 7.09684 5.45455 8.18182C5.45455 9.2668 5.88555 10.3073 6.65275 11.0745C7.41994 11.8417 8.46048 12.2727 9.54545 12.2727C10.6304 12.2727 11.671 11.8417 12.4382 11.0745C13.2054 10.3073 13.6364 9.2668 13.6364 8.18182C13.6364 7.09684 13.2054 6.0563 12.4382 5.28911C11.671 4.52191 10.6304 4.09091 9.54545 4.09091Z'
                            fill='black'
                            fill-opacity='0.5'
                          />
                        </svg>
                      </span>
                    </button>
                  </div>
                  <button class='m-2'>
                    <svg
                      class='svg-inline--fa text-[#0057FF] fa-paper-plane fa-w-16 w-12 h-12 py-2 mr-2'
                      aria-hidden='true'
                      focusable='false'
                      data-prefix='fas'
                      data-icon='paper-plane'
                      role='img'
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 512 512'>
                      <path
                        fill='currentColor'
                        d='M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z'
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;

const MessageBox = () => {
  return (
    <div className='flex justify-between mx-3 h-14 border-b border-gray-300 cursor-pointer'>
      <div className='flex mx-2 space-x-3'>
        <div className='Avatar'>
          <img
            className='inline-block h-10 w-10 rounded-full ring-2 ring-white'
            src='https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
            alt=''
          />
        </div>
        <div className='Info flex flex-col '>
          <p className='font-poppins font-bold text-lg leading-5'>kiran</p>
          <p className='font-poppins text-sm overflow-ellipsis '>Hi</p>
        </div>
      </div>
      <div className='flex flex-col my-2'>
        <p className='font-poppins text-sm'>Yesterday</p>
        <p className='font-poppins text-sm'>6:27pm</p>
      </div>
    </div>
  );
};

const ChatMessageLeft = () => {
  return (
    <div class='chat-messageLeft'>
      <div class='flex items-end'>
        <div class='flex flex-col space-y-2 text-base max-w-xs mx-2 order-2 items-start'>
          <div>
            <span class='px-4 py-2 rounded-lg inline-block rounded-bl-none bg-gray-300 text-gray-600'>
              Hey There!
            </span>
          </div>
          <p>
            <span class='px-4  rounded-lg inline-block text-xs   text-gray-600'>
              {new Date().toDateString()}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

const ChatMessageRight = () => {
  return (
    <div class='chat-messageRight'>
      <div class='flex items-end justify-end'>
        <div class='flex flex-col space-y-2 text-base max-w-xs mx-2 order-1 items-end'>
          <div>
            <span class='px-4 py-2 rounded-lg inline-block rounded-br-none bg-blue-600 text-white'>
              Hello
            </span>
          </div>
          <p>
            <span class='px-4  rounded-lg inline-block text-xs   text-gray-600'>
              {new Date().toDateString()}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};
