import FeaturedTopics from "./components/FeaturedTopics/FeaturedTopics";
import Footer from "./components/Footer/Footer";
import Hero from "./components/Hero/Hero";
import ProcessInfo from "./components/ProcessInfo/ProcessInfo";
import ProductInfo from "./components/ProductInfo/ProductInfo";
import SubscribeForm from "./components/SubscribeForm/SubscribeForm";
import StatsInfo from "./components/StatsInfo/StatsInfo";
import Testimonials from "./components/Testimonials/Testimonials";
import Details from "./components/Deatails/details";

const Landing = () => {
  return (
    <div className="grid grid-flow-row auto-rows-max md:auto-rows-min ">
      <Hero />
      <ProductInfo />
      <Details />
      <FeaturedTopics />
      <ProcessInfo />
      <StatsInfo />
      <Testimonials />
      <SubscribeForm />
      <Footer />
    </div>
  );
};

export default Landing;
