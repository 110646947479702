import world from "../../images/worldimage.jpg";

const Testimonials = () => {
  return (
    <div className="Testimonials align-middle justify-center items-center p-6 ">
      <h1 className="text-4xl text-gray-600  text-center">Our Testimonials</h1>
      {/* <div className="testimony font-bold text-2xl color-[#275350]"> */}
      <div className="flex gap-6 justify-center align-middle content-center p-10 pb-0  sm:max-w-[540px] md:max-w-[720px] lg:max-w-[1140px] m-auto">
        <img src={world} alt="world" className="rounded-full h-56 w-56"/>
        <section className="flex flex-col p-4">
          <h2 className="text-2xl font-bold">
            Erick Mutuma,{" "}
            <span className="text-xl font-medium text-gray-600">
              phd student{" "}
            </span>
          </h2>
          <p className="processCard__text text-[#275350] leading-6 text-lg font-normal py-2">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Debitis
            amet ex veniam voluptas dolorum, cum, minus accusamus temporibus
            suscipit molestias architecto quae nemo, nostrum quos consectetur
            rem fuga. Excepturi, illo?
          </p>
        </section>
        <div></div>
      </div>
    </div>
    // </div>
  );
};
export default Testimonials;
