const NavLink = ({ link, text, onClick }) => {
  return (
    <button
      onClick={onClick}
      class='block py-2.5 px-4 rounded transition duration-200 hover:bg-[#527e7b] text-[#C0BEFF] hover:text-white'>
      {text}
    </button>
  );
};

export default NavLink;
