import Select from "react-select";
import { NumberInput } from "./NumberInput";
import { data } from "./data";

const HeroForm = () => {
  const spacing = data.spacing;
  const citations = data.citations;
  const selectStyles = data.selectStyles;
  return (
    <div
      className="heroForm__container h-auto bg-[#275350] bg-opacity-75 p-4 rounded-md xs:w-full 
     md:w-[450px] lg:w-[500px] grid grid-cols-1 gap-3 font-roboto"
    >
      <div className="flex justify-center">
        <h2 className="text-[#FDC800] font-sans font-normal text-2xl">
          Calculate
        </h2>
      </div>

      <form action="" className="flex flex-col py-2 text-gray-200 mt-3 ">
        <div className="flex justify-around mt-3 items-center">
          <label
            htmlFor=""
            className="text-base font-normal  font-sans text-gray-200 w-1/2"
          >
            Am looking for:
          </label>
          <div className="flex-grow text-gray-200">
            <Select
              options={spacing}
              defaultValue={spacing[0]}
              styles={selectStyles}
            />
          </div>
        </div>
        <div className="flex justify-around mt-3 items-center">
          <label
            htmlFor=""
            className="text-base font-normal  font-sans w-1/2"
          >
            Academic level:
          </label>
          <div className="flex-grow text-white">
            <Select
              options={spacing}
              defaultValue={spacing[0]}
              styles={selectStyles}
            />
          </div>
        </div>

        <div className="flex justify-around mt-3 items-center">
          <label
            htmlFor=""
            className="text-base font-normal  font-sans text-gray-200 w-1/2"
          >
            Citration Style:
          </label>
          <div className="flex-grow">
            <Select
              options={spacing}
              defaultValue={spacing[0]}
              styles={selectStyles}
            />
          </div>
        </div>

        <div className="flex justify-around mt-3 items-center">
          <label
            htmlFor=""
            className="text-base font-normal  font-sans text-gray-200 w-1/2"
          >
            Number of Pages:
          </label>
          <NumberInput />
        </div>
        <div className="flex justify-around mt-3 items-center">
          <label
            htmlFor=""
            className="text-base font-normal  font-sans text-gray-200 w-1/2"
          >
            Line Spacing:
          </label>
          <div className="flex-grow ">
            <Select
              options={spacing}
              defaultValue={spacing[0]}
              styles={selectStyles}
            />
          </div>
        </div>
        <div className="flex justify-around mt-3 items-center">
          <label
            htmlFor=""
            className="text-base font-normal  font-sans text-gray-200 w-1/2"
          >
            Citation name:
          </label>
          <div className="flex-grow">
            <Select
              options={citations}
              defaultValue={citations[0]}
              styles={selectStyles}
            />
          </div>
        </div>
        <div className="flex justify-around mt-3 items-center">
          <label
            htmlFor=""
            className="text-base font-normal  font-sans text-gray-200 w-1/2"
          >
            Urgency:
          </label>
          <div className="flex-grow ">
            <Select
              options={spacing}
              defaultValue={spacing[0]}
              styles={selectStyles}
            />
          </div>
        </div>
      </form>
      <div className="flex justify-start ml-4 mt-3 gap-6">
        <button className="bg-yellow-500 text-white py-2 px-4">
          CALCULATE
        </button>
        <h3 className="text-white text-xl font-bold">
          Your price is : $00.00{" "}
        </h3>
      </div>
      <div className="flex justify-start mt-3">
        <h3 className="text-white w-full">
          <button className="bg-yellow-500 py-2 px-10 l-10 place-content-center w-full">
            CONTINUE TO ORDER
          </button>
        </h3>
      </div>
    </div>
  );
};

export default HeroForm;
