import { HiOutlineNewspaper } from "react-icons/hi";
import { MdMoneyOff } from "react-icons/md";
import { GiNotebook } from "react-icons/gi";
import { GiBookPile } from "react-icons/gi";
import { FcPrivacy } from "react-icons/fc";
import { GiReceiveMoney } from "react-icons/gi";

export const FeaturedTopicsCard = ({ title, img, svg }) => {
  return (
    <div class="max-w-sm rounded shadow-lg font-roboto transform transition duration-500 hover:scale-95 cursor-pointer  border-2 border-gray-400">
      <img class="w-full" src={img} alt="" />
      <div class="px-6 py-4 outline-2">
        <div className="flex w-16 h-8 mb-10  relative">{svg}</div>
        <div class="font-bold text-xl mb-2">{title}</div>
        <p class="text-gray-700 text-base outline-2">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus
          quia, nulla! Maiores et perferendis eaque, exercitationem praesentium
          nihil.
        </p>
      </div>
    </div>
  );
};

const FeaturedTopics = () => {
  return (
    <div className="processInfo__container mt-8 font-roboto ">
      <div className="flex justify-center xs:mt-4 lg:mt-8">
        <h1 className="text-5xl leading-10 font-normal text-[#275350] font-roboto my-3">
          Our Features
        </h1>
      </div>
      <div className="lg:grid sm:block md:block container mt-4 mx-auto ">
        <div className="lg:grid sm:block md:block grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-y-10 outline-2 xs:justify-center mx-3">
          <FeaturedTopicsCard
            title="Original Paper"
            svg={<HiOutlineNewspaper size={70} color="#f59e0b" />}
          />
          <FeaturedTopicsCard
            title="No hidden Fees"
            svg={<MdMoneyOff size={70} color="#f59e0b" />}
          />
          <FeaturedTopicsCard
            title="Free Revision"
            svg={<GiNotebook size={70} color="#f59e0b" />}
          />
          <FeaturedTopicsCard
            title="Wide Topic Coverage"
            svg={<GiBookPile size={70} color="#f59e0b" />}
          />
          <FeaturedTopicsCard
            title="Privacy Guranteed"
            svg={<FcPrivacy size={70} color="#f59e0b" />}
          />
          <FeaturedTopicsCard
            title="Money Back Guarantee"
            svg={<GiReceiveMoney size={70} color="#f59e0b" mb-8 />}
          />
        </div>
      </div>
    </div>
  );
};

export default FeaturedTopics;
