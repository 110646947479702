import logo from "../../images/logo.svg";
import location from "../../images/location.svg";
import mail from "../../images/mail.svg";
import phone from "../../images/phone.svg";
import twitter from "../../images/twitter.svg";
import facebook from "../../images/facebook.svg";
import instagram from "../../images/instagram.svg";

const Footer = () => {
  return (
    <div className="footer__container bg-[#275350] grid xs:grid-cols-1 font-roboto">
      <div className="footer__top container w-full md:p-20 grid grid-cols-1 md:grid-cols-2  ">
        <div className="footer__left  w-2/5 flex flex-col  text-white justify-center xs:self-center px-8 py-3">
          <div className="logo ">
            <a href="..." className=" flex space-x-3 mx-4">
              {" "}
              <img src={logo} alt="logo" />
              <span className="text-white text-2xl">EssayGenius</span>
            </a>
          </div>
          <div className="footer__left--info container  flex flex-col justify-start space-y-4">
            <p className="flex justify-start space-x-4 ">
              <img src={location} alt="location" className="w-5 h-5" />
              <span className="text-sm leading-5">
                1500 Treat Ave, Suite 200San Francisco, CA 94110
              </span>
            </p>
            <p className="flex justify-start space-x-4">
              <img src={mail} alt="mail" className="w-5 h-5" />
              <span className="text-sm leading-5">vctung@essaygenius.com</span>
            </p>
            <p className="flex justify-start space-x-4">
              <img src={phone} alt="phone" className="w-5 h-5 " />
              <span className="text-sm leading-5">
                1-800-800-2299 (Support)
              </span>
            </p>
          </div>
        </div>
        <div className="  footer__right  grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 grid-flow-row-dense gap-x-4 gap-y-4 px-8 py-3">
          <div className="  links flex flex-col mx-2">
            <h1 className="text-lg font-normal leading-6 text-white">
              Community
            </h1>
            <a href="..." className="footer_links">
              Learners
            </a>
            <a href="..." className="footer_links">
              Patners
            </a>
            <a href="..." className="footer_links">
              Developers Beta
            </a>
            <a href="..." className="footer_links">
              Testers
            </a>
            <a href="..." className="footer_links">
              Translators
            </a>
            <a href="..." className="footer_links">
              BlogTech
            </a>
            <a href="..." className="footer_links">
              BlogTeaching
            </a>
            <a href="..." className="footer_links">
              Center
            </a>
          </div>
          <div className=" links flex flex-col mx-2">
            <h1 className="text-lg font-normal leading-6 text-white">
              Information
            </h1>
            <a href="..." className="footer_links">
              About
            </a>
            <a href="..." className="footer_links">
              Pricing
            </a>
            <a href="..." className="footer_links">
              Blog
            </a>
            <a href="..." className="footer_links">
              Careers
            </a>
            <a href="..." className="footer_links">
              Contact
            </a>
          </div>
          <div className=" links flex flex-col mx-2">
            <h1 className="text-lg font-normal leading-6 text-white">More</h1>
            <a href="..." className="footer_links">
              Press
            </a>
            <a href="..." className="footer_links">
              Investors
            </a>
            <a href="..." className="footer_links">
              Terms
            </a>
            <a href="..." className="footer_links">
              Privacy
            </a>
            <a href="..." className="footer_links">
              Help
            </a>
            <a href="..." className="footer_links">
              Accessibility
            </a>
            <a href="..." className="footer_links">
              Contact
            </a>
            <a href="..." className="footer_links">
              Articles
            </a>
            <a href="..." className="footer_links">
              Directory Affiliates
            </a>
          </div>
        </div>
      </div>
      <div className="footer__socials bg-[#FDC800] flex xs: flex-col md:flex-row md: justify-between p-7 md:mx-40">
        <div className="social__left">
          <p>
            Copyright <span>&copy;</span> {new Date().getFullYear()} EssayGenius
          </p>
        </div>
        <div className="social__right flex space-x-10 p-2">
          <a href="..." className="footer_links">
            <img src={twitter} alt="twitter icon" />
          </a>
          <a href="..." className="footer_links">
            <img src={facebook} alt="facebook icon" />
          </a>
          <a href="..." className="footer_links">
            <img src={instagram} alt="instagram icon" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
