import { useState, useEffect } from 'react';
import RegistrationContainer from "../RegistrationContainer/RegistrationContainer";
import google from "../../images/google.svg";
import facebook from "../../images/facebookBtn.svg";
import apple from "../../images/apple.svg";
import SocialButton from "./SocialButton";
import Error from "../Error/Error"
import { Link, useNavigate } from "react-router-dom";
import {connect} from "react-redux"
import { userActions } from "../../redux/actions/authentication"
import Navbar from "../Navbar/Navbar"


const Login = ({login, error, loggedIn}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate(); 



  useEffect(() => {
    if(loggedIn) {
      navigate("/client-account");
    }
  }, [loggedIn, navigate])

  const handleLogin = (e) => {
    e.preventDefault();
    if(email && password) {
      login(email, password)
    }
  }
  const emailOnChange = (e) => {
    setEmail(e.target.value)
  }

  const passwordOnChange = (e) => {
    setPassword(e.target.value)
  }
  
  return (
    <div> 
    <Navbar />
    <div className='login__container grid xs:grid-cols-1 md:grid-cols-2  font-roboto  h-[93vh] bg-[#E5E5E5] text-[#4A5568]'>
      <div className='login__form xs:order-last md:order-first  gap-2 mx-auto  flex items-center'>
        <form action='' className='grid grid-rows-5 gap-2'>
          <div className='flex justify-center'>
            <h2 className='self-center justify-center text-3xl font-black leading-9 text-[#1A202C]'>
              Sign in to your account
            </h2>
          </div>
          {error && (
            <Error title="Error!" message={error} />
          )}
          <div className='flex flex-col justify-start'>
            <label htmlFor='' className='text-base leading-5 text-[#4A5568]'>
              Email
            </label>
            <input
              type='text'
              onChange={emailOnChange}
              className='text-md block px-3 py-2  rounded-md w-full 
                 border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none'
            />
          </div>
          <div className='flex flex-col justify-start'>
            <label htmlFor='' className='text-base leading-5 text-[#4A5568]'>
              Password
            </label>
            <input
              type='password'
              onChange={passwordOnChange}
              className='text-md block px-3 py-2  rounded-md w-full 
                bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none'
            />
          </div>
          <div className='flex flex-row items-center justify-around'>
            <p>
              <span>
                <input type='checkbox' class='leading-loose cursor-pointer ' />
              </span>{" "}
              Remember me
            </p>
            <Link to='/register'>
              <p className='text-blue-500'>Forgot password?</p>
            </Link>
          </div>
          <div>
            <button
              className='mt-3 text-lg font-semibold 
                bg-yellow-500 w-full text-white rounded-lg
                px-6 py-3 block shadow-xl hover:text-white hover:bg-yellow-600'
                onClick={handleLogin}>
              Sign In
            </button>
          </div>
          <p className='flex px-2 justify-center text-base leading-5 text-[#4A5568] '>
            Dont have an account?{" "}
            <span className='px-2'>
              <Link to='/register'>
                <p className='text-blue-500'>Join Free today</p>
              </Link>
            </span>
          </p>
          <div className='socials'>
            <div className='social__title grid grid-flow-col  items-center my-2'>
              <div className='border-2 border-[#C8D3F9] flex-grow'></div>
              <p
                className=' bg-white text-[#444B59]
              font-nunito font-normal text-lg leading-7 self-center mx-auto px-14 py-2'>
                Or Continue With
              </p>
              <div className='border-2  border-[#C8D3F9]'></div>
            </div>
            <div className='social_buttons flex items-start py-5 px-5'>
              <SocialButton icon={google} />
              <SocialButton icon={facebook} />
              <SocialButton icon={apple} />
            </div>
          </div>
        </form>
      </div>
      <div className='registrationContainer hidden md:block'>
        <RegistrationContainer />
      </div>
      </div>
    </div>
  );
};
const mapDispatchToProps = {
  login: userActions.login
}

const mapStateToProps = (state) => ({
  error: state.authentication.error,
  loggedIn: state.authentication.loggedIn
})

export default connect(mapStateToProps, mapDispatchToProps)(Login);
