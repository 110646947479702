import { handleResponse } from "../../utils/utils"

export const registerService = {
    register
}

const config = {
    apiUrl: process.env.REACT_APP_API_URL,
};

function register(data) {
const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username: data.userName, ...data }),
};
return fetch(`${config.apiUrl}/api/auth/local/register`, requestOptions)
    .then(handleResponse)
    .then((data) => {
        localStorage.setItem("token", JSON.stringify(data.jwt));
        return data;
    });
}


  
  