import { orderConstants } from "../actions/actionCreators";

const initialState = {
    loading: false,
    error: '',
    orders: []

}

export function orders(state = initialState, action) {
  switch (action.type) {
    case orderConstants.UPLOAD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case orderConstants.UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case orderConstants.UPLOAD_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case orderConstants.GET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case orderConstants.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        orders: action.orders.orders
      };
    case orderConstants.GET_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}
