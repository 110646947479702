import { IoIosPeople } from "react-icons/io";
import { MdOutlineSpatialTracking } from "react-icons/md";
import { GiReceiveMoney } from "react-icons/gi";
import { BsCreditCard2Back } from "react-icons/bs";

import "./ProductInfo.css";
import PlaceOrder from "../PlaceOrderForm/PlaceOrder";

const ProductInfo = () => {
  return (
    <div className="productInfo__container ml-20 flex flex-col  xs:justify-center float-right justify-center align-center font-roboto">
      <div className="productInfo__top   mb-5 xs:my-10 mt-5 mx-4">
        <PlaceOrder />
      </div>
      <div className="">
        <div className="productInfo__left items-center justify-center flex">
          <div className="productInfo__headings justify-center  ml-20 items-center my-2 text-center ">
            {" "}
            <h1 className="text-[#275350] leading-10 text-5xl items-center  flex flex-1 justify-center align-middle font-medium py-6">
              GET A PERFECT PAPER IN
            </h1>
            <h1 className="text-[#275350]items-center  flex flex-1  align-middle text-center justify-center leading-10 text-5xl font-medium">
              4 EASY STEPS
            </h1>
            <p className="text-xl font-normal mt-3 items-center  flex flex-1 justify-center align-middlejustify-center text-center text-[#505F98]">
              We offer an easy ordering process. Just take afew simple steps to
              recieve 100% plagiarsm. Free content.
            </p>
          </div>
          <div className="productInfo__text p-1 flex flex-1"></div>
        </div>
        <div className="productInfo__right">
          <div className="flex sm:flex-col md:flex-col lg:flex-row gap-4 p-4  ">
            <div className="lg:grid sm:block md:block grid-flow-row-dense gap-4">
              <div className="items-center  flex flex-1 justify-center align-middle">
                {<BsCreditCard2Back size={70} color="#f59e0b" mb-8 />}
              </div>
              <h4 className="leading-7 text-lg text-center  font-bold">
                Step 1 - make an Order.
              </h4>
              <p className="leading-5 text-center text-base text-gray-600">
                Just fill the order form with all your assignment requirements
                in detail
              </p>
            </div>

            <div className="grid grid-flow-row-dense gap-4">
              <div className="items-center  flex flex-1 justify-center align-middle">
                {<IoIosPeople size={70} color="#f59e0b" mb-8 />}
              </div>
              <h4 className="leading-7 text-lg  text-center  mt-1 font-bold">
                Step 2 - Asign the Expert.
              </h4>
              <p className="leading-5 text-base text-center  text-gray-600">
                We assign the top quality experts as per the assignment demand.
              </p>
            </div>

            <div className="grid grid-flow-row-dense gap-4">
              <div className="items-center  flex flex-1 justify-center align-middle">
                {<GiReceiveMoney size={70} color="#f59e0b" mb-8 />}
              </div>
              <h4 className="leading-7 text-lg  text-center mt-1 font-bold">
                Step 3 - Payment
              </h4>
              <p className="leading-5 text-base text-center  text-gray-600 ">
                You will have the best price for your assignment.Pay through our
                secure payment methods.
              </p>
            </div>

            <div className="grid grid-flow-row-dense gap-4">
              <div className="items-center  flex flex-1 justify-center align-middle">
                {<MdOutlineSpatialTracking size={70} color="#f59e0b" mb-8 />}
              </div>
              <h4 className="leading-7 text-lg text-center mt-1 font-bold">
                Step 4 - Track Progress.
              </h4>
              <p className="leading-5 text-base text-center  text-gray-600">
                Talk to your expert and get updates on your paper at any
                time.Take control of the entire writting process.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductInfo;
