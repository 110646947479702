// import vectorUp from "../../images/vectorup.svg";
// import Select from "react-select";
import { Fragment } from "react";

// const topics = [
//   {
//     label: "Research Writing",
//     value: "Research Writing",
//   },
//   {
//     label: "Social Sciences",
//     value: "Social Sciences",
//   },
//   {
//     label: "Mathematics & Statistics",
//     value: "Mathematics & Statistics",
//   },
//   {
//     label: "Business & Entrepreneurship",
//     value: "Business & Entrepreneurship",
//   },
//   {
//     label: "Creative Writing",
//     value: "Creative Writing",
//   },
//   {
//     label: "History & Politics",
//     value: "History & Politics",
//   },
// ];

// const selectStyles = {
//   input: (styles) => ({
//     ...styles,
//     backgroundColor: "transparent",
//     outline: "none",
//   }),
//   control: (styles, { isFocused }) => ({
//     ...styles,
//     backgroundColor: "transparent",
//     outline: isFocused ? "none" : "none",
//     boxShadow: "none",
//     border: 0,
//   }),
//   option: (styles, { isFocused, isSelected }) => {
//     return {
//       ...styles,
//       outline: isFocused ? "none" : "none",
//     };
//   },
// };

const PlaceOrder = () => {
  return (
    <Fragment>
      {/* <div className="bg-[#275350] xs:w-full md:w-auto h-40  flex flex-col xs:m-4 md:mx-auto md:container rounded-lg p-3 xs:-mt-10 md:-mt-20">
        <div className="placeOrder__top my-3">
          <h3 className="font-normal text-lg leading-5 text-white">
            Place Order
          </h3>
        </div> */}
        {/* 
        <form
          action=""
          className="placeOrder__form rounded-md flex  xs:flex-col md:flex-row  border-2 border-gray-500  h-20 xs:w-full"
        >
          <div className="flex  w-1/3 border-r-2 border-gray-500 ">
            <div className="form__icon flex items-center w-8">
              <img
                src={vectorUp}
                alt="Vector up icon"
                className="object-contain ml-2"
              />
            </div>
            <div className="form__right flex flex-col justify-evenly flex-grow p-1">
              <h3 className="font-normal text-sm leading-4 text-[#E7E7F2] self-center">
                Assignment Topic
              </h3>
              <Select
                options={topics}
                defaultValue="Select a topic"
                styles={selectStyles}
              />
            </div>
          </div>
          <div className="flex flex-col w-1/3 border-r-2 border-gray-500 justify-evenly p-1 ">
            <h3 className="font-normal text-sm leading-4 text-[#E7E7F2] self-center">
              Assignment Level
            </h3>

            <Select
              options={topics}
              defaultValue="Level of Class"
              styles={selectStyles}
            />
          </div>
          <div className="w-1/3 flex flex-col justify-around p-1">
            <h3 className="font-normal text-sm leading-4 text-[#E7E7F2] self-center">
              Assignment timeline
            </h3>
            <Select
              options={topics}
              defaultValue="Timeline"
              styles={selectStyles}
            />
          </div>
        </form> */}
      {/* </div> */}
    </Fragment>
  );
};

export default PlaceOrder;
