import { useState } from "react";
import paypal from "../../images/paypal.svg";
import CreditCard from "./CreditCard";
import { useNavigate } from "react-router-dom";

const Billing = () => {
  const [creditCard, setCreditCard] = useState(true);
  const [payPal, setPayPal] = useState(false);

  let navigate = useNavigate();

  function handleClick() {
    navigate("/client-account");
  }

  return (
    <div className=' bg-white lg:w-[829px] h-[600px] md:h-[400px] lg:h-[500px] shadow-lg rounded'>
      <div className='formContainer flex flex-col items-center justify-center h-full'>
        <div className='paymentMethod grid grid-flow-col gap-3 justify-center mx-2'>
          <button
            className='w-[145.33px] h-16 rounded-md py-3 px-3 ring-2 ring-blue-700 flex flex-col focus:outline-black'
            onClick={() => {
              setCreditCard(true);
              setPayPal(false);
            }}>
            <svg
              width='17'
              height='16'
              viewBox='0 0 17 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'>
              <g clip-path='url(#clip0)'>
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M16.5 4H0.5V3.25C0.5 2.56 0.948 2 1.5 2H15.5C16.052 2 16.5 2.56 16.5 3.25V4ZM16.5 6.5V13C16.5 13.2652 16.3946 13.5196 16.2071 13.7071C16.0196 13.8946 15.7652 14 15.5 14H1.5C1.23478 14 0.98043 13.8946 0.792893 13.7071C0.605357 13.5196 0.5 13.2652 0.5 13V6.5H16.5ZM4.5 10C4.23478 10 3.98043 10.1054 3.79289 10.2929C3.60536 10.4804 3.5 10.7348 3.5 11C3.5 11.2652 3.60536 11.5196 3.79289 11.7071C3.98043 11.8946 4.23478 12 4.5 12H5.5C5.76522 12 6.01957 11.8946 6.20711 11.7071C6.39464 11.5196 6.5 11.2652 6.5 11C6.5 10.7348 6.39464 10.4804 6.20711 10.2929C6.01957 10.1054 5.76522 10 5.5 10H4.5Z'
                  fill='#0570DE'
                />
              </g>
              <defs>
                <clipPath id='clip0'>
                  <rect
                    width='16'
                    height='16'
                    fill='white'
                    transform='translate(0.5)'
                  />
                </clipPath>
              </defs>
            </svg>
            <p>Card</p>
          </button>
          <button
            className='ring-2 ring-gray-500 inline-flex w-[145.33px] h-16 rounded-md py-3 px-3 justify-center focus:outline-black'
            onClick={() => {
              setPayPal(true);
              setCreditCard(false);
            }}>
            <img src={paypal} alt='' />
          </button>
          <button className='ring-2 ring-gray-500 flex flex-col w-[145.33px] h-16 rounded-md py-3 px-3 justify-center'>
            <svg
              width='17'
              height='16'
              viewBox='0 0 17 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'>
              <g clip-path='url(#clip0)'>
                <path
                  d='M0.166626 3.512C0.166626 2.125 0.992808 1 2.01026 1H14.323C15.3404 1 16.1666 2.125 16.1666 3.512V12.488C16.1656 12.8194 16.1171 13.1473 16.0238 13.4529C15.9306 13.7585 15.7945 14.0359 15.6233 14.2692C15.4521 14.5024 15.2491 14.6871 15.026 14.8125C14.8029 14.9378 14.564 15.0016 14.323 15H2.01026C0.992081 15 0.166626 13.875 0.166626 12.488V3.512ZM0.893899 12.472C0.893899 13.352 1.36881 14 2.01317 14H8.15572V2H2.01317C1.36881 2 0.893899 2.647 0.893899 3.528V12.472ZM10.0124 12.375H11.5274V9.042H11.5441C11.8306 9.772 12.4037 10.043 12.9332 10.043C14.2379 10.043 14.9354 8.536 14.9354 6.723C14.9354 5.239 14.2714 3.625 13.059 3.625C12.3688 3.625 11.7303 4.015 11.4263 4.874H11.4088V3.768H10.0124V12.375ZM13.3703 6.77C13.3703 7.747 13.0255 8.419 12.4532 8.419C11.9484 8.419 11.5274 7.747 11.5274 6.852C11.5274 5.934 11.8968 5.25 12.4532 5.25C13.043 5.25 13.371 5.958 13.371 6.77H13.3703Z'
                  fill='#727F96'
                />
                <path
                  d='M6.93537 3.765V9.095C6.93537 11.575 6.04882 12.375 4.25682 12.375C3.67949 12.3773 3.10589 12.2478 2.55937 11.992L2.63646 10.346C3.10482 10.659 3.49755 10.844 4.13827 10.844C5.02482 10.844 5.50264 10.277 5.50264 9.094V8.77H5.48518C5.11864 9.477 4.60664 9.802 3.99137 9.802C2.77246 9.802 2.01318 8.551 2.01318 6.766C2.01318 4.97 2.63646 3.625 4.01755 3.625C4.67427 3.625 5.203 4.112 5.52882 4.855H5.54482V3.765H6.93537ZM3.59937 6.732C3.59937 7.67 4.00955 8.202 4.46991 8.202C5.01537 8.202 5.45173 7.577 5.45173 6.65C5.45173 5.978 5.16082 5.225 4.46991 5.225C3.89827 5.225 3.59937 5.909 3.59937 6.732Z'
                  fill='#EE3525'
                />
              </g>
              <defs>
                <clipPath id='clip0'>
                  <rect
                    width='16'
                    height='16'
                    fill='white'
                    transform='translate(0.166626)'
                  />
                </clipPath>
              </defs>
            </svg>
            <p>GiroPay</p>
          </button>
        </div>
        {creditCard && <CreditCard />}
        {payPal && (
          <div className='mt-5'>
            <button
              className='bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-base px-8 py-3 rounded-xl shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
              type='button'>
              Pay with PayPal
            </button>
          </div>
        )}
      </div>
      <button
        className='self-end bg-transparent hover:bg-[#275350] text-black hover:text-white active:bg-[#5d817f] border border-[#275350] text-lg px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none my-3 ease-linear transition-all duration-150 flex items-center mx-4'
        type='button'
        onClick={handleClick}>
        <span className='mr-2'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            class='h-6 w-6'
            fill='none'
            viewBox='0 0 24 24'
            stroke='currentColor'>
            <path
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              d='M7 16l-4-4m0 0l4-4m-4 4h18'
            />
          </svg>
        </span>
        Back
      </button>
    </div>
  );
};

export default Billing;
