import NumberInput from "./NumberInput";
import { data } from "../HeroForm/data";
import ButtonRight from "../ClientAccount/ButtonRight";


const OrderDetails = ({ nextTab, jobTitle, setJobTitle, education, setEducation, pages, setPages, setCitation, setSpaces }) => {
    const spacing = data.spacing;
    const citations = data.citations;
    return (
      <div className='mx-2'>
        <div className='grid grid-rows-5 gap-6 container mx-auto items-center justify-center my-3 w-full p-2'>
          <div className='flex justify-between mt-3 items-center text-[#777373]'>
            <p className='space-x-2'>Job Title</p>
            <input
              id={jobTitle}
              onChange={(e) => setJobTitle(e.target.value)}
              type='text'
              className='w-[275px] h-[45px] border-2 border-[#777373] outline-none'
            />
          </div>
          <div className='flex justify-between mt-3 items-center text-[#777373]'>
            <p className='space-x-4'>Level of Education</p>
            <input
              type='text'
              value={education}
              id={education}
              onChange={(e) => setEducation(e.target.value)}
              className='w-[275px] h-[45px] border-2 border-[#777373] space-x-2  outline-none'
            />
          </div>
          <div className='flex justify-between mt-3 items-center'>
            <label
              htmlFor=''
              className='text-base font-normal  font-sans text-[#777373] space-x-4 '>
              Number of Pages:
            </label>
            <NumberInput pages={pages} setPages={setPages} />
          </div>
          <div className='flex justify-between mt-3 items-center'>
            <label
              htmlFor=''
              className='text-base font-normal  font-sans text-[#777373] space-x-2'>
              Line Spacing:
            </label>
            <div>
              <select
                name=''
                id=''
                onChange={(e)=> setSpaces(e.target.value)}
                placeholder='Select Citation'
                className='w-[275px] h-[45px] bg-transparent border-2 border-[#777373] outline-none'>
                {spacing.map(({ label, value }) => (
                  <option key={label} value={value}>
                    {label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className='flex justify-between mt-3 items-center'>
            <label
              htmlFor=''
              className='text-base font-normal  font-sans text-[#777373] space-x-2'>
              Citation name:
            </label>
            <div className='w-[275px] h-[45px] '>
              <select
                name=''
                onChange={(e) => setCitation(e.target.value)}
                id=''
                placeholder='Select Citation'
                className='w-[275px] h-[45px] bg-transparent border-2 border-[#777373] outline-none'>
                {citations.map(({ label, value }) => (
                  <option key={label} value={value}>
                    {label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className='navigationButtons flex justify-end mx-auto'>
          <ButtonRight handleClick={nextTab} />
        </div>
      </div>
    );
  };
export default OrderDetails