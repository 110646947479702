const ButtonRight = ({ handleClick }) => {
  return (
    <>
      <button
        className='self-end bg-[#275350] text-white active:bg-[#5d817f]   text-lg px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none my-3 ease-linear transition-all duration-150 flex items-center'
        type='button'
        onClick={handleClick}>
        <span className='mr-2'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            class='h-6 w-6'
            fill='none'
            viewBox='0 0 24 24'
            stroke='currentColor'>
            <path
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              d='M14 5l7 7m0 0l-7 7m7-7H3'
            />
          </svg>
        </span>
        Next
      </button>
    </>
  );
};

export default ButtonRight;
