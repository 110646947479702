import { userConstants } from "../actions/actionCreators";

const token = localStorage.getItem('token')
const initialState = {
    loggedIn: token ? true: false,
    user: null,
    loading: false,
    error: ''
}

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        user: action.user,
        loading: false,
        error: ''
      };
    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case userConstants.GET_USER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_USER_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        user: action.user,
        loading: false,
        error: ''
      };
    case userConstants.GET_USER_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case userConstants.LOGOUT:
      return {
        ...state,
        loggedIn: false,
      };
    default:
      return state;
  }
}
