import SocialButton from "../Login/SocialButton";
import RegistrationContainer from "../RegistrationContainer/RegistrationContainer";
import google from "../../images/google.svg";
import facebook from "../../images/facebookBtn.svg";
import apple from "../../images/apple.svg";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {  useState } from "react";
import { registerActions } from '../../redux/actions/register';
import Error from "../Error/Error"
import Navbar from "../Navbar/Navbar"

const Register = ({register, error}) => {

  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [confPassword, setConfPassword] = useState('');

  const handleChange = (e) => {
    const {name, value} = e.target
    if(name === "userName"){
      setUserName(value)
    }
    else if ( name === "email") {
      setEmail(value)
    }
    else if( name === "phoneNumber") {
      setPhoneNumber(value)
    }
    else if( name === "password") {
      setPassword(value)
    }
    else if( name === "confPassword") {
      setConfPassword(value)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if(userName && email && phoneNumber && password) {
      register({userName, email, phoneNumber, password})
    }
  }
  return (
    <div>
      <Navbar />
      <div className='register__container grid xs:grid-cols-1 md:grid-cols-2 bg-[#E5E5E5] h-[93vh] font-roboto'>
        <div className='register__form xs:order-last md:order-first  gap-2 mx-auto  flex items-center'>
          <form action='' className='grid grid-rows-5 gap-2'>
            <div className='flex justify-center'>
              <h2 className='self-center justify-center text-3xl font-black leading-9 text-[#1A202C]'>
                Sign Up to your account
              </h2>
            </div>
            {error && (
              <Error title="Error!" message={error} />
            )}
            <div className='flex flex-col justify-start'>
              <label htmlFor='' className='text-base leading-5 text-[#4A5568]'>
                Name
              </label>
              <input
                type='text'
                name='userName'
                value={userName}
                onChange={handleChange}
                className='text-md block px-3 py-2  rounded-lg w-full 
                  bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none'
              />
              {!userName&&(<span class="inline-flex text-sm text-red-700">Please fill out the name</span>)}
            </div>
            <div className='flex flex-col justify-start'>
              <label htmlFor='' className='text-base leading-5 text-[#4A5568]'>
                Email
              </label>
              <input
                type='text'
                name='email'
                onChange={handleChange}
                className='text-md block px-3 py-2  rounded-lg w-full 
                  bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none'
              />  
            {!email&&(<span class="inline-flex text-sm text-red-700">Please fill out the email</span>)}
            </div>
            <div className='flex flex-col justify-start'>
              <label htmlFor='' className='text-base leading-5 text-[#4A5568]'>
                Phone Number
              </label>
              <input
                type='text'
                name='phoneNumber'
                onChange={handleChange}
                className='text-md block px-3 py-2  rounded-lg w-full 
                  bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none'
              />
              {!phoneNumber&&(<span class="inline-flex text-sm text-red-700">Please fill your phone number</span>)}
            </div>
            <div className='flex flex-col justify-start'>
              <label htmlFor='' className='text-base leading-5 text-[#4A5568]'>
                Password
              </label>
              <input
                name='password'
                onChange={handleChange}
                type='password'
                className='text-md block px-3 py-2  rounded-lg w-full 
                  bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none'
              />
              {!password&&(<span class="inline-flex text-sm text-red-700">Please fill your password</span>)}
            </div>
            <div className='flex flex-col justify-start'>
              <label htmlFor='' className='text-base leading-5 text-[#4A5568]'>
                Confirm Password
              </label>
              <input
                type='password'
                onChange={handleChange}
                name='confPassword'
                className='text-md block px-3 py-2  rounded-lg w-full 
                  bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none'
              />
              {(!confPassword || confPassword !== password || !password) &&(<span class="inline-flex text-sm text-red-700">Passwords should match</span>)}
            </div>
            <div className='flex flex-row items-center justify-start'>
              <p>
                <span>
                  <input
                    type='checkbox'
                    class='leading-loose cursor-pointer text-pink-600'
                  />
                </span>{" "}
                Remember me
              </p>
            </div>
            <div>
              <button
                onClick={handleSubmit}
                className='mt-3 text-lg font-semibold 
                  bg-yellow-500 w-full text-white rounded-lg
                  px-6 py-3 block shadow-xl hover:text-white hover:bg-yellow-600'>
                Sign Up
              </button>
            </div>
            <p className='flex px-2 justify-center text-base leading-5 text-[#4A5568] '>
              Already have an account?{" "}
              <span className='px-2'>
                <Link to='/login'>
                  <p href='' className='text-blue-500'>
                    Sign In
                  </p>
                </Link>
              </span>
            </p>
            <div className='socials'>
              <div className='social__title grid grid-flow-col  items-center my-2'>
                <div className='border-2 border-[#C8D3F9] flex-grow'></div>
                <p
                  className=' bg-white text-[#444B59]
                font-nunito font-normal text-lg leading-7 self-center mx-auto px-14 py-2'>
                  Or Continue With
                </p>
                <div className='border-2  border-[#C8D3F9]'></div>
              </div>
              <div className='social_buttons flex items-start py-5 px-5'>
                <SocialButton icon={google} />
                <SocialButton icon={facebook} />
                <SocialButton icon={apple} />
              </div>
            </div>
          </form>
        </div>
        <div className='registration-container hidden md:block'>
          <RegistrationContainer />
        </div>
      </div>
    </div>
  );
};
const mapDispatchToProps = {
  register: registerActions.register
}

const mapStateToProps = (state) => ({
  error: state.registration.error
})

export default connect(mapStateToProps, mapDispatchToProps) (Register);
