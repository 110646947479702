const Profile = () => {
  return (
    <div className='Profile-Container'>
      <div className='topContainer'>
        <div className='Header flex flex-col mx-6 my-6'>
          <p className='justify-start text-[21px] leading-[24px] font-normal text-[#333269]'>
            Hello, John
          </p>
          <button
            className='self-end bg-[#275350] text-white active:bg-[#5d817f]   text-lg px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none my-3 ease-linear transition-all duration-150'
            type='button'>
            Upload Job
          </button>
        </div>
      </div>
      <div className='bottomContainer flex flex-col lg:flex-row'>
        <div className='leftContainer w-auto lg:w-1/5 flex flex-row lg:flex-col justify-center items-center my-3'>
          <div className='avatarContainer bg-white w-[250px] h-[230px] flex justify-center items-center flex-col p-3 rounded-md shadow-md mx-3'>
            <img
              className='inline-block h-[95px] w-[95px] rounded-full ring-2 ring-white'
              src='https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
              alt=''
            />
            <div className='info font-inter text-center py-4 '>
              <h3 className='text-base leading-4 font-bold'>
                Amos Katana Kenga
              </h3>
              <p className='font-normal text-xs leading-4 '>me@gmail.com</p>
            </div>
          </div>
          <div className='avatarLinkContainer bg-white mt-2 w-[250px] h-[230px] rounded-md p-3 shadow-md mx-3'>
            <div className='flex  h-10 border-b border-gray-300 items-center mx-2 hover:border-[#5d817f]'>
              <button className='flex justify-center items-center  '>
                <svg
                  width='13'
                  height='15'
                  viewBox='0 0 13 15'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M6.125 7.75C8.03906 7.75 9.625 6.19141 9.625 4.25C9.625 2.33594 8.03906 0.75 6.125 0.75C4.18359 0.75 2.625 2.33594 2.625 4.25C2.625 6.19141 4.18359 7.75 6.125 7.75ZM8.55859 8.625H8.09375C7.49219 8.92578 6.83594 9.0625 6.125 9.0625C5.41406 9.0625 4.73047 8.92578 4.12891 8.625H3.66406C1.64062 8.625 0 10.293 0 12.3164V13.4375C0 14.1758 0.574219 14.75 1.3125 14.75H10.9375C11.6484 14.75 12.25 14.1758 12.25 13.4375V12.3164C12.25 10.293 10.582 8.625 8.55859 8.625Z'
                    fill='#275350'
                  />
                </svg>
                <p className='font-inter font-medium text-base text-black mx-3'>
                  My Profile
                </p>
              </button>
            </div>
            <div className=' flex h-10 border-b border-gray-300 items-center mx-2 hover:border-[#5d817f]'>
              <button className='flex justify-center items-center'>
                <svg
                  width='15'
                  height='15'
                  viewBox='0 0 15 15'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M8.92969 3.32031L1.32812 10.9219L1 14.0391C0.945312 14.4492 1.30078 14.8047 1.71094 14.75L4.82812 14.4219L12.4297 6.82031L8.92969 3.32031ZM14.5898 2.80078L12.9492 1.16016C12.457 0.640625 11.6094 0.640625 11.0898 1.16016L9.55859 2.69141L13.0586 6.19141L14.5898 4.66016C15.1094 4.14062 15.1094 3.29297 14.5898 2.80078Z'
                    fill='#828181'
                  />
                </svg>

                <p className='font-inter font-medium text-base text-black mx-3'>
                  Edit Profile
                </p>
              </button>
            </div>
            <div className='flex h-10 border-b border-gray-300 items-center mx-2 hover:border-[#5d817f]'>
              {" "}
              <button className='flex items-center'>
                <svg
                  width='14'
                  height='11'
                  viewBox='0 0 14 11'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M12.6875 2.25H7.4375L5.6875 0.5H1.3125C0.574219 0.5 0 1.10156 0 1.8125V9.6875C0 10.4258 0.574219 11 1.3125 11H12.6875C13.3984 11 14 10.4258 14 9.6875V3.5625C14 2.85156 13.3984 2.25 12.6875 2.25Z'
                    fill='#828181'
                  />
                </svg>

                <p className='font-inter font-medium text-base text-black mx-3'>
                  Manage Account
                </p>
              </button>
            </div>
            <div className='flex h-10 border-b border-gray-300 items-center mx-2 hover:border-[#5d817f]'>
              {" "}
              <button className='flex items-center'>
                <svg
                  width='14'
                  height='12'
                  viewBox='0 0 14 12'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M2.1875 8.8125H0.4375C0.191406 8.8125 0 9.03125 0 9.25V11C0 11.2461 0.191406 11.4375 0.4375 11.4375H2.1875C2.40625 11.4375 2.625 11.2461 2.625 11V9.25C2.625 9.03125 2.40625 8.8125 2.1875 8.8125ZM2.1875 0.0625H0.4375C0.191406 0.0625 0 0.28125 0 0.5V2.25C0 2.49609 0.191406 2.6875 0.4375 2.6875H2.1875C2.40625 2.6875 2.625 2.49609 2.625 2.25V0.5C2.625 0.28125 2.40625 0.0625 2.1875 0.0625ZM2.1875 4.4375H0.4375C0.191406 4.4375 0 4.65625 0 4.875V6.625C0 6.87109 0.191406 7.0625 0.4375 7.0625H2.1875C2.40625 7.0625 2.625 6.87109 2.625 6.625V4.875C2.625 4.65625 2.40625 4.4375 2.1875 4.4375ZM13.5625 9.25H4.8125C4.56641 9.25 4.375 9.46875 4.375 9.6875V10.5625C4.375 10.8086 4.56641 11 4.8125 11H13.5625C13.7812 11 14 10.8086 14 10.5625V9.6875C14 9.46875 13.7812 9.25 13.5625 9.25ZM13.5625 0.5H4.8125C4.56641 0.5 4.375 0.71875 4.375 0.9375V1.8125C4.375 2.05859 4.56641 2.25 4.8125 2.25H13.5625C13.7812 2.25 14 2.05859 14 1.8125V0.9375C14 0.71875 13.7812 0.5 13.5625 0.5ZM13.5625 4.875H4.8125C4.56641 4.875 4.375 5.09375 4.375 5.3125V6.1875C4.375 6.43359 4.56641 6.625 4.8125 6.625H13.5625C13.7812 6.625 14 6.43359 14 6.1875V5.3125C14 5.09375 13.7812 4.875 13.5625 4.875Z'
                    fill='#828181'
                  />
                </svg>

                <p className='font-inter font-medium text-base text-black mx-3'>
                  Logs
                </p>
              </button>
            </div>
            <div className='flex h-10 border-b border-gray-300 items-center mx-1 hover:border-[#5d817f]'>
              {" "}
              <button className='flex items-center flex-wrap'>
                <svg
                  width='14'
                  height='15'
                  viewBox='0 0 14 15'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M14 5.58984C14 2.91016 11.8398 0.75 9.16016 0.75C6.50781 0.777344 4.375 2.91016 4.375 5.5625C4.375 6.10938 4.45703 6.60156 4.59375 7.09375L0.191406 11.4961C0.0546875 11.6328 0 11.7969 0 11.9609V14.0938C0 14.4766 0.273438 14.75 0.65625 14.75H3.71875C4.07422 14.75 4.375 14.4766 4.375 14.0938V13H5.46875C5.82422 13 6.125 12.7266 6.125 12.3438V11.25H7.13672C7.32812 11.25 7.49219 11.168 7.62891 11.0312L8.28516 10.293C8.55859 10.3477 8.85938 10.375 9.1875 10.375C11.8398 10.375 14 8.24219 14 5.58984ZM9.1875 4.25C9.1875 3.53906 9.76172 2.9375 10.5 2.9375C11.2109 2.9375 11.8125 3.53906 11.8125 4.25C11.8125 4.98828 11.2109 5.5625 10.5 5.5625C9.76172 5.5625 9.1875 4.98828 9.1875 4.25Z'
                    fill='#828181'
                  />
                </svg>
                <p className='font-inter font-medium text-base text-black mx-3'>
                  Password & Security
                </p>
              </button>
            </div>
          </div>
        </div>
        <div className='rightContainer lg:w-4/5 flex justify-center items-center'>
          <div className='w-full lg:w-[880px] h-[400px] lg:h-[445px] bg-white mx-3 rounded-lg shadow-lg'>
            <div className='topContainer bg-[#F7F8FC] font-inter p-4'>
              <div className=' flex w-auto lg:w-[600px] justify-around'>
                <TopText text='Jobs' number='36' />
                <TopText text='Completed' number='28' />
                <TopText text='In Progress' number='12' />
                <TopText text='Pending' number='8' />
              </div>
            </div>
            <div className='BottomContainer'>
              <div className='topInfo flex justify-between mx-6 items-center'>
                <h1 className='text-[#275350] font-medium text-2xl leading-9'>
                  Personal Information
                </h1>
                <button
                  className='self-end bg-[#275350] text-white active:bg-[#5d817f]   text-lg px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none my-3 ease-linear transition-all duration-150'
                  type='button'>
                  Edit Profile
                </button>
              </div>
              <div className='bottomInfo text-[#275350] font-inter grid grid-cols-2 gap-3 w-full md:w-[446px] place-items-center h-auto md:h-[187px]'>
                <div className='flex flex-col py-2'>
                  <p className='text-base leading-5 font-semibold uppercase'>
                    Full Name
                  </p>
                  <p className='text-sm leading-4 font-normal'>
                    Amos Katana Kenga
                  </p>
                </div>
                <div className='flex flex-col py-2'>
                  <p className='text-base leading-5 font-semibold uppercase '>
                    Joined
                  </p>
                  <p className='text-sm leading-4 font-normal'>May 20, 2021</p>
                </div>
                <div className='flex flex-col py-2'>
                  <p className='text-base leading-5 font-semibold uppercase'>
                    Email Address
                  </p>
                  <p className='text-sm leading-4 font-normal'>me@gmail.com</p>
                </div>
                <div className='flex flex-col py-2'>
                  <p className='text-base leading-5 font-semibold uppercase'>
                    Phone Number
                  </p>
                  <p className='text-sm leading-4 font-normal'>0723107115</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;

const TopText = ({ text, number }) => {
  return (
    <div className='flex flex-col font-inter'>
      <p className='text-[#828181] font-semibold text-base'>{text}</p>
      <p className='font-semibold text-3xl'>{number}</p>
    </div>
  );
};
