import ButtonLeft from "../ClientAccount/ButtonLeft";

const OrderAttachments = ({ previousTab, setAttachments}) => {

  const handleFileChange = (e) => {
    if (!e.target.files) {
      return;
    }
    setAttachments(e.target.files);
  };

  return (
    <div className=' md:h-80 grid grid-flow-row items-center justify-center gap-4 my-4'>
      <div className='grid grid-flow-col items-center justify-center gap-4 my-4'>
        <p className='text-[#777373] text-xl'>Attachments:</p>
        <input
          className='xs:w-[362px] xs:h-[79px]  text-gray-700 p-6 text-xl border-4 focus:outline-none mb-11'
          type="file"
          multiple
          placeholder='Attachments'
          onChange={handleFileChange}
          />
      </div>
      <div className='nav-Buttons flex justify-between'>
        <ButtonLeft handleClick={previousTab} />{" "}
      </div>
    </div>
  );
};

export default OrderAttachments;
