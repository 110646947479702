
const NumberInput = (pages) => {
    return (
      <div class='custom-number-input w-[275px] h-[45px] border-2 border-[#777373] bg-transparent flex items-center'>
        <div class='flex flex-row h-10 w-full  relative bg-transparent mt-1'>
          <button
            data-action='decrement'
            onClick={() => pages.setPages(pages.pages - 1)}
            class='  h-full w-20 rounded-l cursor-pointer outline-none'>
            <span class='m-auto text-2xl font-thin'>−</span>
          </button>
          <input
            type='number'
            min='1'
            class='outline-none focus:outline-none text-center w-full bg-transparent font-semibold text-md hover:text-black focus:text-black   flex items-center text-[#777373]  outline-none'
            name='custom-input-number'
            value={pages.pages}></input>
          <button
            data-action='increment'
            onClick={(e) => {
              e.preventDefault();
              pages.setPages(pages.pages + 1);
            }}
            class='h-full w-20 rounded-r cursor-pointer'>
            <span class='m-auto text-2xl font-thin'>+</span>
          </button>
        </div>
      </div>
    );
  };

  export default NumberInput;