const Details = () => {

    return (
        <div className=" overflow-auto h-[310px] bg-[#ECECEC] "> 
            <div className="sm:max-w-[540px] md:max-w-[720px] lg:max-w-[1140px] m-auto">
                <div className="text-center">
                    <p className="text-[30px] font-bold p-5"> WELCOME TO ESSAY GENIUS </p>
                    <p className="text-[15px]"> BEST CUSTOM ESSAY WRITING SERVICES UK & USA </p>
                </div>
                <div className="text-[14px]">
                    <p className="font-bold p-5"> How Can You Trust EssaysMaster Writing Services? </p>
                    <p className="mt-[10px]"> EssaysMaster is well known and renowned organisation which is providing best essay writing service from west to east. The organisation is well known and popular in terms of providing quality essay writing services and the organisation has qualified and experienced writers to provide you service facilities related to the academic writing.</p>
                    <p className="mt-[10px]"> Essay required proper idea and concept which is need to be expressed and shared with appropriate facts and sources by giving proper justification and conducting a proper analysis. It is critically important to understand the methods of writing essay in order to support the explanation by giving proper understanding on the return context. The content of the essay need to be specific and precise by highlighting upon the actual facts to give proper understanding between the readers and the tutors. Before writing the essay it is important to structure the main objective of the topic so that the essay can be framed with proper content. Moreover, the essay can be formal or it can be argumentative essay. Therefore, our organisation take care of such aspects for delivering a quality content to the students by expressing the logical aspects and structuring the format of the paper in a professional way. Our company focus on the objective of providing quality writing service to the students so that they can secure good grade in their academic career with essay writing services and other academic writing facilities.</p>
                    <p className="font-bold p-5"> Is EssaysMaster Will Be Delivered My Paper On Time?</p>
                    <p className="mt-[10px]"> EssaysMaster understands the problem and the situation of each and every students is our prime objective, as they need to provide quality essay content to  institute and college. For that reason we are available for the students 24/7. Student from different regions and countries contact us for getting service assistant on essay writing and also for other academic writing services. Maximum number of students who had taken service assistance from us had achieved good grade in their academic career as our main intention is to provide them quality report by understanding their requirements.</p>
                    <p className="mt-[10px]"> Several essay writing service organisations are existing in different region and country but EssaysMaster is successful in their business career because of providing efficient service facility in terms of writing essay papers and articles for the student. There are numerous student from different colleges who found that our services are good as compared to the other organisation as we are very much streak towards other into the guideline of any academic paper. Moreover, we have expert writer who are into the service of academic writing from long period of time. They are very much precise to with their writing skill and they know what actually need to be provided on essay paper and on article so that the student can secure good grade in their academic career. One of the most important reason to choose EssaysMaster for getting writing assistance on academic papers is that before delivering the paper, we ensure that every guideline and the instruction has been followed. In addition to that the paper has been checked numerous time by our quality checker so that each and every sentences and the sources given on the paper is correct and it is been properly structured as per the requirement. For that reason, the quality of our writing is very much precise so that student can get flying grades in their academic career.</p>
                    <p className="font-bold p-5"> What If You Don’t Get a Satisfactory Paper From Our Service?</p>
                    <p className="mt-[10px]"> Being in the service of providing writing assistance and services to the student from long period of time, we understand the actual requirement of the students and how to structure the paper according to the guideline. We have worked with different students from different colleges and universities. Therefore it has become quite easy for us how to structure and format the paper according to the university requirement. Moreover, we understand what the actual requirements of the university are and what information that need to be provided in the paper.</p>
                    <p className="mt-[10px]"> To provide service related to the writing, students can visit to our website through which they can order for the academic paper by giving the details and they can even calculate the price of the paper. Ordering the paper from our website is very much easy and it will allow students from different universities and colleges to get the writing assistance at any time by visiting the website. Moreover we also provide constant offers and discounts on essay papers as there are many students who are not financially strong to get the writing service facility and we understand that money is important for every student. Therefore, we make paper for the students at a very reasonable cost by providing those discounts and offers on different papers.</p>
                    <p className="font-bold p-5"> How Do I Choose EssaysMaster Expert for My Work?</p>
                    <p className="mt-[10px]"> The success of our organisation is entirely dependent upon the number of students who has satisfied with their writing services. More than 90% of the students who has taken writing assistance from us has got excellent grade in the academic career and we feel very much proud for ensuring the satisfaction level of the students.</p>
                    <p className="mt-[10px]"> In 2013, our organisation has also taken part in consumer protection campaign so that we can provide authentic information to the students rather than providing any fake resources. Our organisation is very strict regarding any conduct of fraud. We always keep the information of the student confidential so that it cannot be manipulated and misinterpreted by other organisation. Moreover, we ensure that after getting best essay writing services from our organisation, students will always get a good grade in their academic career and even it is our strong believe that the student will ask for the service again as we are very much precise in fulfilling the requirement of the student. Many students has become our regular customer and they always like to take service from us because of getting excellent grade from the university. Our organisation is very much good and effective in analysing the problems and the actual verdict of the report. For the purpose of getting any editing facility related to the writing services, we also do editing on the paper from the scratch by adding appropriate and reliable information and sources to make it precise and professional.</p>
                </div>
            </div>
        </div>
    )

}

export default Details;