import login from "../../images/login.svg";

const RegistrationContainer = () => {
  return (
    <div className='flex xs:hidden md:flex-col items-center justify-center h-full mx-auto'>
      <img src={login} alt='' className='h-[618px] w-[620px]' />
    </div>
  );
};

export default RegistrationContainer;
