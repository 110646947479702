import { handleResponse } from "../../utils/utils"
export const loginService = {
    login,
    getUser,
}

const config = {
    apiUrl: process.env.REACT_APP_API_URL,
};

function login(email, password) {
const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ identifier:email, password }),
};
return fetch(`${config.apiUrl}/api/auth/local`, requestOptions)
    .then(handleResponse)
    .then((data) => {
        const token = localStorage.getItem('token')
        if(token) {
            localStorage.removeItem('token')
        }
        localStorage.setItem("token", data.jwt);
        return data;
    });
}



function getUser() {
    const token = localStorage.getItem('token')
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json", 'Authorization': 'Bearer ' + token }
    };
    return fetch(`${config.apiUrl}/api/users/me`, requestOptions)
        .then(handleResponse)
        .then((data) => {
            return data;
        });
    }

  
  