import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { ClientAccount } from "./components/ClientAccount/ClientAccount";
import ClientAccountPayment from "./components/ClientAccountPayment/ClientAccountPayment";
import Login from "./components/Login/Login";
import Register from "./components/Register/Register";
import Landing from "./Landing";
import { connect } from "react-redux"

const App = ({loggedIn}) => {
  return (
    <Router>
      <Routes>
        <Route path="*" element={
          <RequireAuth loggedIn={loggedIn} redirectTo='/login'>
            <Routes>
              <Route path='/client-account' element={<ClientAccount />} />
              <Route
                path='/client-account-payment'
                element={<ClientAccountPayment />}
              />
            </Routes>
          </RequireAuth>
        }
        />
          <Route exact path='/' element={<Landing />} />
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Register />} />
      </Routes>
    </Router>
  );
};
const mapStateToProps = (state) => ({
  loggedIn: state.authentication.loggedIn
})


function RequireAuth({ children, redirectTo, loggedIn}) {
  console.log(loggedIn)
  return loggedIn ? children : <Navigate to={redirectTo} />;
}

export default connect(mapStateToProps, null) (App);
