import { userConstants } from "./actionCreators";
import {registerService} from "../API/register"

export const registerActions = {
    register,
}

function register(data) {
    return (dispatch) => {
      dispatch(request());
      registerService.register(data).then(
        (user) => {
            
          dispatch(success(user));
          dispatch(loginSucess(user))
        //   history.push("/");
        },
        (error) => {
          dispatch(failure(error.message));
        },
      );
    };
  
    function request() {
      return { type: userConstants.REGISTER_REQUEST };
    }
  
    function success(user) {
      return { type: userConstants.REGISTER_SUCCESS, user };
    }

    function loginSucess(user) {
        return { type: userConstants.LOGIN_SUCCESS, user };
    }
  
    function failure(error) {
      return { type: userConstants.REGISTER_FAILURE, error };
    }
  }
  