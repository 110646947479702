import { data } from "../HeroForm/data";

const countries = data.countries;
const CreditCard = () => {
  return (
    <div className='mt-3'>
      <div className='flex flex-col my-3 mx-4'>
        <label htmlFor='' className='creditCardText my-2'>
          Card number
        </label>
        <input
          type='text'
          placeholder='1234 1234 1234 1234'
          className='h-11 w-full creditCardText rounded-md inline-flex justify-center shadow-md bg-[#F7FAFC] ring-2 ring-gray-400 '
        />
      </div>
      <div className='flex justify-between my-2 p-3'>
        <div className='flex flex-col '>
          <p className='creditCardText my-1'>Expiry</p>
          <input
            type='text'
            placeholder='MM/YY'
            className='h-11 creditCardText  rounded-md inline-flex justify-center p-2 shadow-md bg-[#F7FAFC] ring-2 ring-gray-400 '
          />
        </div>
        <div className='flex flex-col ml-2'>
          <p className='creditCardText my-1'>CVC</p>
          <input
            type='number'
            placeholder='CVC'
            className='h-11 creditCardText appearance-none rounded-md inline-flex justify-center shadow-md p-2 bg-[#F7FAFC] ring-2 ring-gray-400 '
            maxlength='3'
          />
        </div>
      </div>
      <div className='flex justify-evenly'>
        <div className='flex flex-col'>
          <p className='creditCardText my-2'>Country</p>
          <select
            name=''
            id=''
            className='h-11 creditCardText  rounded-md inline-flex justify-center p-2 shadow-md bg-[#F7FAFC] ring-2 ring-gray-400 w-48'>
            {countries.map(({ name, code }) => (
              <option value={name} key={code}>
                {name}
              </option>
            ))}
          </select>
        </div>
        <div className='flex flex-col'>
          <p className='creditCardText my-2'>Postal Code</p>
          <input
            type='text'
            placeholder='Postal Code'
            className='h-11 rounded-md inline-flex justify-center shadow-md bg-[#F7FAFC] creditCardText ring-2 ring-gray-400 justify-center'
          />
        </div>
      </div>
    </div>
  );
};

export default CreditCard;
