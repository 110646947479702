import "./Hero.css";
import background from "../../images/hero.svg";
import HeroForm from "../HeroForm/HeroForm";
import Navbar from "../Navbar/Navbar";

function Hero() {
  return (
    <div
      className="hero__container font-assistant "
      style={{ backgroundImage: `url(${background})` }}
    >
       <div className="border-slate-400 border-b">
          
          <Navbar />
          </div>
      <div className="hero__content ml-10 h-full grid xs:grid-cols-1 lg:grid-cols-2 items-center gap-2">
        <div className="hero__title p-4 md:p-5 xs:mx-auto grid grid-cols-1 gap-2 w-full">
          <h1 className="text-xl md:text-3xl text-gray-300 font-normal font-sans">
            The profound custom essay writing service for all your academic
            writing needs.
          </h1>
          {/* <h1 className="text-xl md:text-4xl text-gray-300 font-normal ">
            Something Together
          </h1> */}
          <p className="text-2xl font-normal  font-sans text-gray-300 ">
            Get your essay written starting at just $14.00 a page
          </p>
          {/* className="text-2xl font-normal  font-sans text-gray-200" */}
          <p className="text-gray-300">
            By clicking "Proceed", you agree to our terms of service and privacy
            policy. We'll occasionally send you promo and account related
            emails.
          </p>
        </div>
        <div className="hero__form h-full xs:mx-auto xs:flex justify-center md:mx-auto flex items-center w-full">
          <HeroForm />
        </div>
      </div>
    </div>
  );
}

export default Hero;
