import { Link } from "react-router-dom";
import ButtonLeft from "../ClientAccount/ButtonLeft";

const OrderBill = ({ previousTab }) => {
  return (
    <div>
      {" "}
      <div className='grid grid-flow-row md:w-[630px] md:h-[597px] bg-[#EAEAEA] mx-2 my-3 rounded-lg'>
        <h1 className='self-center mx-auto font-normal text-3xl leading-9 text-[#275350]'>
          My Order
        </h1>
        <div className='grid grid-flow-col'>
          <div className='Info flex flex-col gap-2 mx-auto'>
            <p className='font-normal text-[#275350] text-sm leading-8'>
              Job: <span>Title</span>
            </p>
            <p className='font-normal text-[#275350] text-sm leading-8'>
              Topic: <span>Health and Diseases</span>
            </p>
            <p className='font-normal text-[#275350] text-sm leading-8'>
              Level of Education: <span>High School</span>
            </p>
            <p className='font-normal text-[#275350] text-sm leading-8'>
              Timeline: <span>One Week</span>
            </p>
            <p className='font-normal text-[#275350] text-sm leading-8'>
              Line Spacing: <span>Single</span>
            </p>
            <p className='font-normal text-[#275350] text-sm leading-8'>
              Number of Words: <span>600</span>
            </p>
          </div>
          <div className='Logo'>
            <svg
              width='291'
              height='291'
              className='transition-all duration-1000 animate-pulse'
              viewBox='0 0 291 291'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M84.875 181.875H169.75V206.125H84.875V181.875ZM84.875 133.375H206.125V157.625H84.875V133.375ZM84.875 84.875H206.125V109.125H84.875V84.875ZM230.375 36.375H179.692C174.6 22.31 161.262 12.125 145.5 12.125C129.738 12.125 116.4 22.31 111.308 36.375H60.625C58.9275 36.375 57.3512 36.4963 55.775 36.86C51.0462 37.83 46.8025 40.255 43.5287 43.5287C41.3462 45.7113 39.5275 48.3787 38.315 51.2887C37.1025 54.0775 36.375 57.23 36.375 60.625V230.375C36.375 233.649 37.1025 236.923 38.315 239.833C39.5275 242.743 41.3462 245.289 43.5287 247.592C46.8025 250.866 51.0462 253.291 55.775 254.261C57.3512 254.504 58.9275 254.625 60.625 254.625H230.375C243.712 254.625 254.625 243.712 254.625 230.375V60.625C254.625 47.2875 243.712 36.375 230.375 36.375ZM145.5 33.3438C150.471 33.3438 154.594 37.4662 154.594 42.4375C154.594 47.4087 150.471 51.5312 145.5 51.5312C140.529 51.5312 136.406 47.4087 136.406 42.4375C136.406 37.4662 140.529 33.3438 145.5 33.3438ZM230.375 230.375H60.625V60.625H230.375V230.375Z'
                fill='#275350'
              />
            </svg>
          </div>
        </div>
        <div className='Price flex justify-center items-center'>
          <p className='font-normal text-[#275350] text-lg leading-8'>
            Total Price:{" "}
          </p>
          <p className='font-normal text-7xl leading-[88px] underline text-[#275350]'>
            $50.00
          </p>
        </div>
      </div>
      <div className='flex justify-around'>
        <ButtonLeft handleClick={previousTab} />
        <Link to='/client-account-payment'>
          <button
            className='self-end bg-[#FDC800] text-white active:bg-[#fdc60088]   text-lg px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none my-3 ease-linear transition-shadow duration-1000 text-[#275350] mx-5 '
            type='button'>
            Proceed to Payment
          </button>
        </Link>
      </div>
    </div>
  );
};

export default OrderBill;
