import { useForm } from "react-hook-form";
import { MdEmail } from "react-icons/md";

const SubscribeForm = () => {
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => {
    console.log(data);
  };
  return (
    <div className="  grid grid-flow-row justify-center mb-6 mt-2 py-3 w-full">
      <h3 className="text-base font-roboto  leading-5 font-bold text-[#275350] place-self-center">
        Subscribe now
      </h3>
      <h2 className="text-3xl leading-10 text[#002147] my-3 font-roboto">
        Get every single update you will get
      </h2>
      <div className="w-auto mx-5">
        <form className="w-full " onSubmit={handleSubmit(onSubmit)}>
          <div className="flex items-center justify-between align-middle content-center py-1 border rounded bg-white border-[#275350]">
            <MdEmail className="ml-[20px] h-[50px] w-[30px]" />
            <input
              className="bg-transparent outline-none w-full text-gray-700  py-2 px-2 leading-tight "
              type="email"
              placeholder="Enter your email address"
              aria-label="Full name"
              ref={register()}
            />
            <button
              className="subscribe-btn bg-yellow-500 text-white active:bg-yellow-600 font-bold p-2 text-sm py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1  ease-linear transition-all duration-150"
              type="button"
            >
              subscribe now
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SubscribeForm;
