import { useState } from "react";

export const NumberInput = () => {
  const [pageNumber, setPageNumber] = useState(0);
  return (
    <div class='custom-number-input h-10 w-32 flex-grow bg-transparent border-2 rounded-md flex items-center'>
      <div class='flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1'>
        <button
          data-action='decrement'
          onClick={() => setPageNumber(pageNumber - 1)}
          class='  h-full w-20 rounded-l cursor-pointer outline-none'>
          <span class='m-auto text-2xl font-thin'>−</span>
        </button>
        <input
          type='number'
          min='1'
          class='focus:outline-none text-center w-full bg-transparent font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-white  outline-none'
          name='custom-input-number'
          value={pageNumber}></input>
        <button
          data-action='increment'
          onClick={(e) => {
            e.preventDefault();
            setPageNumber(pageNumber + 1);
          }}
          class='h-full w-20 rounded-r cursor-pointer'>
          <span class='m-auto text-2xl font-thin'>+</span>
        </button>
      </div>
    </div>
  );
};
