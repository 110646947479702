import ButtonLeft from "../ClientAccount/ButtonLeft";
import ButtonRight from "../ClientAccount/ButtonRight";

const OrderDescription = ({ previousTab, nextTab, setDescription, description, setTopic, setTimeline, timeline }) => {
  const topics = [
    { id: 1, name: "Health" },
    { id: 2, name: "Business & Entrepreneurship" },
    { id: 3, name: "History & Politics" },
    { id: 4, name: "Creative Writing" },
    { id: 5, name: "Biology" },
    { id: 6, name: "Physics" },
    { id: 7, name: "Social Sciences" },
  ];

  const timelines= [
    {
      name: " Urgent", value: "urgent"
    },
    {
      name: " Normal", value: "normal",
    },
    {
      name: "Long Term", value: "longterm",
    }
  ]
  return (
    <div className='grid grid-flow-row  xs:h-screen md:h-auto container items-center justify-center gap-10'>
      <div className='grid grid-flow-col m-5'>
        <p className='text-[#777373] text-xl'>Topic:</p>
        <select
          name=''
          onChange={(e) => setTopic(e.target.value)}
          id=''
          className='xs:w-[180px] md:w-64 md:h-12 text-gray-700 py-2 px-3 border border-gray-300 bg-white  shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500 self-start'>
          {topics.map(({ id, name }) => (
            <option key={id} value={name}>{name}</option>
          ))}
        </select>
      </div>
      <div className='grid grid-flow-col m-5'>
        <p className='text-[#777373] text-xl'>Timeline:</p>
        <select
          name=''
          onChange={(e) => setTimeline(e.target.value)}
          id=''
          className='xs:w-[180px] md:w-64 md:h-12 text-gray-700 py-2 px-3 border border-gray-300 bg-white  shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500 self-start'>
          {timelines.map(({ value, name }) => (
            <option key={value} value={value}>{name}</option>
          ))}
        </select>
      </div>
      <div className='grid grid-flow-col gap-3 mb-4'>
        <p className='text-[#777373] text-xl'>Description:</p>
        <textarea
          name=''
          id=''
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder='Type Instructions Here...'
          className='xs:w-[180px] xs:h-[200px] md:w-[525px] md:h-[275px] text-gray-700 p-6 text-xl border-4 focus:outline-none'></textarea>
      </div>
      <div className='nav-Buttons flex justify-between'>
        <ButtonLeft handleClick={previousTab} />{" "}
        <ButtonRight handleClick={nextTab} />
      </div>
    </div>
  );
};

export default OrderDescription;
