import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { orderActions } from "../../redux/actions/orders"
import { userActions } from "../../redux/actions/authentication"

const MyOrders = ({getOrders, orders, getUser, error, showNewOrder}) => {

  const [filteredOrders, SetFilteredOrders] = useState(null)
  const [checked, setChecked] = useState(["all"])

  useEffect(() => { 
    function getOrdersAndUser() {
      getUser()
      getOrders()
    }
    getOrdersAndUser();

  }, [getUser, getOrders])

  useEffect(() => {
    
    if(orders){
      SetFilteredOrders(orders)
    }
  }, [orders])
  
 const filterOrders = (name) => {
  
  let allChecked = checked
  if(checked.includes(name)){
    allChecked = allChecked.filter((fil) => fil !== name)
  }
  else {
    allChecked.push(name)
  }
  console.log(showNewOrder)
  setChecked([...allChecked])
  
  if(allChecked.includes('all')) {
    SetFilteredOrders([...orders])
  }
  else {
    // eslint-disable-next-line
    const allfilteredOrders = orders.filter((ord) => {
      if(allChecked.includes(ord.timeline)) {
        return ord;
      }
    })
    SetFilteredOrders(allfilteredOrders)

  }

 }
  return (
    <div className='myOrders__Container flex flex-col md:flex-row  w-full h-full'>
      <div className='LeftContainer flex flex-col xs:w-full md:w-1/6  h-full text-[#333333]  '>
        <div className='mx-auto my-4 container  flex xs:flex-row md:flex-col '>
          <div className='grid xs:grid-row-dense md:h-[300px] '>
            <h3 className='mx-2 my-4 font-roboto font-normal text-2xl leading-6'>
              Filters
            </h3>
            <div className='Inputs flex xs:flex-row md:flex-col'>
              <div className='mx-1' onClick={()=> filterOrders("all")}>
                <label class='inline-flex items-center justify-center'>
                  <input type='checkbox' class='form-checkbox' value="all"  checked={checked.includes("all")}/>
                  <span class='ml-2'>All Jobs</span>
                </label>
              </div>

              <div className='mx-1' onClick={()=> filterOrders("urgent")}>
                <label class='inline-flex items-center'>
                  <input type='checkbox' class='form-checkbox' checked={checked.includes("urgent")}/>
                  <span class='ml-2'>Urgent Jobs</span>
                </label>
              </div>

              <div className='mx-1' onClick={()=> filterOrders("normal")}>
                <label class='inline-flex items-center'>
                  <input type='checkbox' class='form-checkbox' checked={checked.includes("normal")} />
                  <span class='ml-2'>Normal Jobs</span>
                </label>
              </div>

              <div className='mx-1' onClick={()=> filterOrders("longterm")}>
                <label class='inline-flex items-center' >
                  <input type='checkbox' class='form-checkbox' checked={checked.includes("longterm")}/>
                  <span class='ml-2'>LongTerm Jobs</span>
                </label>
              </div>
            </div>
          </div>

          <div className='flex xs:items-center md:justify-start'>
            <a
              href='/'
              alt=''
              className='text-xl font-roboto px-2 hover:text-black hover:underline ease-linear transition-all duration-150 mx-5'>
              Settings
            </a>
          </div>
        </div>
      </div>
      <div className='RightContainer md:border-l border-gray-600  xs:w-full md:w-5/6  h-full flex flex-col justify-around mx-8 relative'>
        <div className='Header flex flex-col mx-3 my-3'>
          <button
            className='self-end bg-[#275350] text-white active:bg-[#5d817f]   text-lg px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none my-3 ease-linear transition-all duration-150'
            type='button'
            onClick={showNewOrder}
            >
            New Job
          </button>
        </div>

        <div className='Container flex flex-col mx-3 my-3'>
          {filteredOrders ? filteredOrders.map((order) => (
              <Assignments
              title={order.jobTitle}
              text={order.description}
              timeline={order.timeline}
              level={order.education}
            />
          )) : (<div> No orders to display </div>) }
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  orders: state.orders.orders,
  error: state.authentication.error
})

const mapDispatchToProps = {
  getOrders: orderActions.getAllOrders,
  getUser: userActions.getUSer
}

export default connect (mapStateToProps, mapDispatchToProps) (MyOrders);

const Assignments = ({ title, text, timeline, level }) => {
  return (
    <div className='rounded-2xl bg-white w-full h-full px-3 mb-4 flex flex-col flex-wrap font-roboto'>
      <h3
        className='font-roboto text-3xl font-normal leading-normal mt-0 mb-2 text-[
#275350]'>
        {title}
      </h3>
      <p
        className='text-base font-light leading-relaxed mt-0 mb-4 text-[
#275350]'>
        {text}
      </p>
      <a
        href='/'
        alt='attachment'
        className='underline font-roboto text-lg font-normal leading-normal mt-0 mb-2 text-[
#275350]'>
        Attachment
      </a>
      <div
        className='flex justify-between text-base font-light leading-relaxed mt-5 mb-4 text-[
#275350]'>
        <p>
          Timeline: <span>{timeline}</span>
        </p>
        <p className='ml-5'>
          Level: <span>{level}</span>
        </p>
      </div>
    </div>
  );
};
